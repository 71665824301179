import React from "react";
import { Col, Row } from "reactstrap";
import Table from "../../components/Table/Table";
import "../../assets/sass/_common.scss";
import SweetAlert from "../../components/sweet-alert/SweetAlert";
import {
  updateDevice,
  DeviceDetails,
  deleteDevice,
  activeInActiveDevice,
  filterTableRecords,
} from "./apiCalls";
import { notificationAction } from "../../redux/reducers/notification_reducer";
import * as ActionTypes from "../../redux/actions/types";
import { connect } from "react-redux";
import DeviceFilter from "./component/Filter";
import { MdEdit } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { IoMdSettings } from "react-icons/io";
import LoadingMask from "../../components/Loader/LoadingMask";
import EditDeviceModal from "./component/EditDeviceModal";
import { Message } from "../../utils/messages";

class Device extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sweetalert: false,
      paginationData: [],
      locId: null,
    
      editModalValues:{
        device_id: null,
        name:"",
        device_location:"",
        attendance_type:"",
        status:null
      },
      selectedLocation:{},
      selectedAttendanceType:{},
    
     
      showEditModal: false,
      validateDevice: {},
      download: true,
      allChecked: false,
      appLoadingState: false,
      DeviceList: [],
      selectedDetailInfo: {},

      selectedDeviceValues: {
        deviceName: null,
        branch: null,
        is_active: null,
      },

      selectedValues: {
        selected_is_active: { label: "All", value: -1 },
        selected_branch: { label: "All", value: null },
      },
    };
  }

  componentDidMount=async ()=> {
    await this.props.RecordPerPage(10)
    await this.props.PageNo(1);
    await this.handleFilterDataTableList();
     
   }

  componentDidUpdate(prevProps) {
    if (
      prevProps.pageno !== this.props.pageno ||
      prevProps.sortname !== this.props.sortname ||
      prevProps.sortby !== this.props.sortby
    ) {
      this.handleFilterDataTableList();
    }
    if (prevProps.recordperpage !== this.props.recordperpage) {
      this.props.PageNo(1);
      this.handleFilterDataTableList();
      this.setState({
        allChecked: false,
      });
    }
  }

  handleFiltersClear = () => {
    this.props.PageNo(1);
    this.props.RecordPerPage(10);
    this.setState(
      {
        selectedDeviceValues: {
          deviceName: "",
        },
        selectedValues: {
          selected_branch: { label: "All", value: null },
          selected_is_active: { label: "All", value: -1 },
        },
      },
      () => this.handleFilterDataTableList()
    );
  };

  handleFilterApply = () => {
    this.handleFilterDataTableList();
  };

  handleFilterDataTableList = () => {
    this.setState({ appLoadingState: true });
    const { selectedDeviceValues, selectedValues } = this.state;
    const { selected_is_active } = selectedValues;
    const { deviceName, branch, is_active } = selectedDeviceValues;
    filterTableRecords(
      this.props.recordperpage ? this.props.recordperpage : null,
      this.props.pageno ? this.props.pageno : null,
      this.props.sortname ? this.props.sortname : null,
      this.props.sortby ? this.props.sortby : null,
      deviceName ? deviceName.replace(/^\s+|\s+$/g, "") : null,
      branch ? branch : null,
      selected_is_active.label !== "All" ? (is_active ? "1" : "0") : null,
    )
      .then((response) => {
        if (response.code == 200) {
          this.setState({
            DeviceList: response.data.dataset,
            paginationData: response.data.pagination,
            appLoadingState: false,
          });
        }
        else if (response.code != 200){
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
          this.setState({
            appLoadingState: false,
          });
        }
      })
      .catch((error) => console.log("Erorr in getting Devices ", error));
  };

  handelDropDownChange = (selectedOption, obj) => {
    const { selectedValues, selectedDeviceValues } = this.state;
    const {is_active}= selectedDeviceValues;
    const { name } = obj;

    if (!selectedOption) {
      let defaultOption = { value: "", label: "All" };
      if (obj.name === "is_active" || obj.name === "branch") {
        this.setState({
          selectedValues: {
            ...selectedValues,
            [`selected_${obj.name}`]: defaultOption,
          },
          selectedDeviceValues: {
            ...selectedDeviceValues,
            [name]: null,
          },
        });
      }
      return;
    } 
   

    if(name == "is_active"){
      this.setState({
        selectedValues: {
          ...selectedValues,
          [`selected_${name}`]: selectedOption,
        },
        selectedDeviceValues: {
          ...selectedDeviceValues,
         is_active: selectedOption.value,
        },
      });
    }
    if(name == "branch"){
      this.setState({
        selectedValues: {
          ...selectedValues,
          [`selected_${name}`]: selectedOption,
        },
        selectedDeviceValues: {
          ...selectedDeviceValues,
          [name]: selectedOption.id
        },
      });
    }
  
  };

  handleCheckOptionToggle = (item) => {
    if (item != null) {
      this.setState({
        allChecked: false,
        DeviceList: this.state.DeviceList.map((s) =>
          s.id == item.id ? { ...item, Checked: !s.Checked } : s
        ),
      });
    } else {
      this.setState({
        allChecked: !this.state.allChecked,
        DeviceList: this.state.DeviceList.map((s) => ({
          ...s,
          Checked: !this.state.allChecked,
        })),
      });
    }
  };
  handelLocationChange = (selectedOption) =>{
    const { editModalValues } = this.state;

    if (!selectedOption) {
      return
    }
    this.setState({
      editModalValues: {
        ...editModalValues,
        device_location:selectedOption.id
      },
      selectedLocation: selectedOption,
    });
    
  }
  handelAttendanceChange = (selectedOption) =>{
    const { editModalValues } = this.state;

    if (!selectedOption) {
      return
    }
    this.setState({
      editModalValues: {
        ...editModalValues,
        attendance_type:selectedOption.value
      },
      selectedAttendanceType: selectedOption,
    });
    
  }
  handleEditDevice = (item) => {
    this.setState({
      editModalValues:{
        ...this.state.editModalValues,
        device_id:item.id
      }
    })
    DeviceDetails(item.id)
    .then((response) => {
      if (response.code == 200) {
        const {name, location, location_id,status,attendance_type_name,attendance_type_value} = response.data;
        this.setState(
          {
            editModalValues:{
              ...this.state.editModalValues,
            device_location:location_id,
            attendance_type:attendance_type_value,
              name:name,
              status:status
            },
            selectedLocation:{label:location, value:location_id},
            selectedAttendanceType:{label:attendance_type_name, value:attendance_type_value},
            showEditModal: true,
          }
        );
      } else if (response.code != 200) {
        this.props.notificationAction(info);
        const info = {
          message: response.message,
          status: "error",
        };
        this.props.history.push("/");
        return;
      }
    })
    .catch((error) => {
      console.log(error);
    });
  };
  handleUpdateDevice = () => {
    const { editModalValues, validateDevice} = this.state;
    if (!editModalValues.name) {
      this.setState({
        validateDevice: {
          ...validateDevice,
          invaliddevice: true,
        },
      });
      const info = {
        message: Message.AddDevice,
        status: "error",
      };
      this.props.notificationAction(info);
      return;
    }
    updateDevice({postData:editModalValues})
    .then((response) => {
      if (response.code == 200) {
        this.setState(
          {
            appLoadingState:false,
            showEditModal:false
          },
        );
        const info = {
          message: response.message,
          status: "success",
        };
        this.props.notificationAction(info);
        this.handleFilterDataTableList();
      } else if (response.code != 200) {
        const info = {
          message: response.message,
          status: "error",
        };
        this.props.notificationAction(info);
        return;
      }
      })
      .catch((error) => {
        console.log(error);
      });
    };
  handleTableButtonClick = (item, type) => {
    if (type == "Configure") {
      this.handleConfigureDevice(item);
    } else if (type == "Edit") {
      this.handleEditDevice(item);
    } else if (type == "Delete") {
      this.handleDeviceDelete(item.id);
    }
  };

  handleToggleButtonClick = (item) => {
    let allDevices = this.state.DeviceList;
    let deviceMapping = allDevices.find(function (element) {
      return element.id == item.id;
    });
    let DeviceStatus = null;
    if (deviceMapping.status == 1) {
      DeviceStatus = 0;
    } else if (deviceMapping.status == 0) {
      DeviceStatus = 1;
    }

    let statusData = {
      device_id: [deviceMapping.id],
      status: DeviceStatus,
    };
    activeInActiveDevice({ statusData })
      .then((response) => {
        if (response.code === 200) {
          const info = {
            message: response.message,
            status: "success",
          };
          this.props.notificationAction(info);
          this.handleFilterDataTableList();
          return;
        }
      })
      .catch((error, response) => {
        const info = {
          message: response.message,
          status: "error",
        };
        this.props.notificationAction(info);
      });
  };
  handleConfigureDevice = (item) => {
    this.props.history.push({
      pathname: "/devices/configure-device/" + item.id,
      state: {
        Id: item.id,
      },
    });
  };
  handleGettingBulkRecord = (item) => {
    const listArray = [];
    item.data.map((item) => {
        listArray.push(item.id);
    });
    let statusData = {
      device_id: listArray,
      status: item.status,
    };
    if (item.status == "delete") {
      activeInActiveDevice({ statusData })
        .then((response) => {
          if (response.code === 200) {
            const info = {
              message: response.message,
              status: "success",
            };
            this.props.notificationAction(info);
            this.handleFilterDataTableList();
            this.setState({
              allChecked: false,
            });
            return;
          }
        })
        .catch((error, response) => {
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
        });
    } else if (item.status == "0") {
      activeInActiveDevice({ statusData })
        .then((response) => {
          if (response.code === 200) {
            const info = {
              message: response.message,
              status: "success",
            };
            this.props.notificationAction(info);
            this.handleFilterDataTableList();
            this.setState({
              allChecked: false,
            });
            return;
          }
        })
        .catch((response) => {
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
        });
    } else if (item.status == "1") {
      activeInActiveDevice({ statusData })
        .then((response) => {
          if (response.code === 200) {
            const info = {
              message: response.message,
              status: "success",
            };
            this.props.notificationAction(info);
            this.handleFilterDataTableList();
            this.setState({
              allChecked: false,
            });
            return;
          }
        })
        .catch((response) => {
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
        });
    }
  };
  handelSweetModalDelete = (e) => {
    if (e) {
      const statusData = {
        person_device_id: [this.state.locId],
        status: "delete",
      };
      deleteDevice({ id:statusData })
        .then((response) => {
          if (response.code === 200) {
            const info = {
              message: response.message,
              status: "success",
            };
            this.setState({
              sweetalert: false,
            });
            this.props.notificationAction(info);
            this.handleFilterDataTableList();
            return;
          }
          else if (response.code != 200){
            const info = {
              message: response.message,
              status: "error",
            };
            this.props.notificationAction(info);
            this.setState({
              sweetalert: false,
            });
          }
        })
        .catch((error, response) => {
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
        });
    
    }
  };
  handleDeviceDelete = (Id) => {
    this.setState({
      locId: Id,
      sweetalert: true,
    });
  };
  handleExportCsv = () => {
    const { download,selectedValues } = this.state;
    const { selected_is_active } = selectedValues;
    const { deviceName, branch, is_active } = this.state.selectedDeviceValues;
    filterTableRecords(
      this.props.recordperpage ? this.props.recordperpage : null,
      this.props.pageno ? this.props.pageno : null,
      this.props.sortname ? this.props.sortname : null,
      this.props.sortby ? this.props.sortby : null,
      deviceName ? deviceName.replace(/^\s+|\s+$/g, "") : null,
      branch ? branch : null,
      selected_is_active.label !== "All" ? (is_active ? "1" : "0") : null,
      download ? 1 : null
    )
      .then((response) => {
        if (response.code == 200) {
          if (response.data.file_url != null) {
            window.open(response.data.file_url, "_blank");
          }
        }
      })
      .catch((error) => console.log("Erorr in getting Devices ", error));
  };
  validateField = ({ name, value }) => {
    const { selectedDeviceValues } = this.state;
    this.setState({
      selectedDeviceValues: {
        ...selectedDeviceValues,
        [name]: value,
      },
    });
  };
  handleFormFieldChange = (e) => {
    const { name, value } = e.target;
    if (name === "search_configuration" || "deviceName") {
      this.validateField({ name, value });
      return;
    }
  };
  handelDeviceNameChange = (e) => {
    const { value } = e.target;
      const { editModalValues, validateDevice } = this.state;
      this.setState({
        editModalValues: {
          ...editModalValues,
          name: value,
        },
        validateDevice: {
          ...validateDevice,
         invaliddevice: !value,
        },
      });
    
  };
  handelStatusChange =()=>{
    const{editModalValues}= this.state;
    this.setState({
      editModalValues: {
        ...editModalValues,
        status: editModalValues.status == "1" ? "0" :"1"
      }
    })
  }
  handelMainModalCLose = () => {
    this.setState({
      showEditModal: false,
      locId: null,
    });
  };
  render() {
    let template;

    template = (
      <div>
        <Row>
          {this.state.appLoadingState ? <LoadingMask /> : null}
          <Col md={3} lg={3}></Col>
          <Col md={9} lg={9}>
            <SweetAlert
              show={this.state.sweetalert}
              deleteRecord={this.handelSweetModalDelete}
            />
          </Col>
        </Row>
        <EditDeviceModal
          show={this.state.showEditModal}
          EditModalValues={this.state.editModalValues}
          onDeviceNameChange={this.handelDeviceNameChange}
          onLocationChange={this.handelLocationChange}
          onUpdate={this.handleUpdateDevice}
          selectedLocation={this.state.selectedLocation}
          validateDevice={this.state.validateDevice}
          onAttendanceTypeChange={this.handelAttendanceChange}
          selectedAttendanceType={this.state.selectedAttendanceType}
          onToggleStatus={this.handelStatusChange}
          onDiscard={this.handelMainModalCLose}
        />
        <DeviceFilter
          onFilterApply={this.handleFilterApply}
          onExportCsv={this.handleExportCsv}
          SelectedValues={this.state.selectedValues}
          selectedFormValues={this.state.selectedDeviceValues}
          onFormFieldChange={this.handleFormFieldChange}
          onDDChange={this.handelDropDownChange}
          onFilterReset={this.handleFiltersClear}
        />
        <Row>
          <Col className="pr-grid-col" xs={12} lg={12}>
            {this.state.DeviceList && this.state.DeviceList.length > 0 ? (
              <Table
                primaryKeyField="sno"
                data={this.state.DeviceList}
                listItemToDisplay={[
                  { name: "Device", valueField: "name" },
                  { name: "Location", valueField: "location" },
                  // { name: "Connection", valueField: "connection" },
                ]}
                onCheckOptionToggle={this.handleCheckOptionToggle}
                isCheckOption={true}
                isBadgeState={[true, "Connection"]}
                allChecked={this.state.allChecked}
                paginationData={this.state.paginationData}
                toggleButtonToDisplay={[true, "status"]}
                buttonsToDisplay={[
                  {
                    name: "Edit",
                    buttonLabel: "Edit",
                    icon: <MdEdit size={22} />,
                    Tooltipmessage: "Edit Device",
                  },
                  {
                    name: "Configure",
                    color: "#FFB534",
                    Tooltipmessage: "Configure Device",
                    icon: <IoMdSettings size={22} />,
                  },
                  {
                    name: "Delete",
                    color: "#CE3939",
                    icon: <MdDelete size={22} />,
                    buttonLabel: "Delete",
                    Tooltipmessage: "Delete Device"
                  },
                ]}
                onTableButtonClick={this.handleTableButtonClick}
                onToggleStatus={this.handleToggleButtonClick}
                onGettingBulkRecords={this.handleGettingBulkRecord}
              />
            ) : (
              <div className="text-center">
                There is no data available to show
              </div>
            )}
          </Col>
        </Row>
      </div>
    );

    return this.props.GoBackValue == false ? (
      <div className="">{template} </div>
    ) : (
      ""
    );
  }
}

const actions = (dispatch) => ({
  notificationAction: (message, status) =>
    dispatch(notificationAction(message, status)),
  goBack: (goback) => dispatch({ type: ActionTypes.GO_BACK, goback }),
  Step1: (step1) => dispatch({ type: ActionTypes.STEP_1, step1 }),
  RecordPerPage: (recordperpage) =>
    dispatch({ type: ActionTypes.RECORDS_PER_PAGE, recordperpage }),
  PageNo: (pageno) => dispatch({ type: ActionTypes.PAGE_NO, pageno }),
});
const mapStateToProps = (state) => ({
  pageno: state.tab.pageno,
  recordperpage: state.tab.recordperpage,
  GoBackValue: state.tog.goback,
  sortby: state.tab.sortBy,
  sortname: state.tab.sortName,
});

export default connect(mapStateToProps, actions)(Device);

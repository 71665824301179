import { ApiUrls } from "../../../utils/apiUrls";
import authAxios from "../../../services/axiosInstance";
import axios from "axios";
export const getAllAttendanceList = (
  per_page,
  page_no,
  order_by,
  order_type,
  person_name,
  person_number,
  department,
  selectedDateRange,
  type,
  download
) => {
  return authAxios
    .get(ApiUrls.Attendance.GET_ALL_STAFF_ATTENDANCE, {
      params: {
        per_page,
        page_no,
        order_by,
        order_type,
        person_name,
        person_number,
        department,
        selectedDateRange,
        type,
        download
      },
    })
    .then(({ data }) => data)
    .catch((response) => response);
};


export const getDetectionById = id => {
  return axios
    .get(ApiUrls.Attendance.GET_ATTENDANCE_BY_ID(id))
    .then(({ data }) => data)
    .catch(response => response);
};
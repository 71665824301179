import React from "react";
import { Col, Row, Button } from "reactstrap";
import Table from "../../../components/Table/Table";
import "../../../assets/sass/_common.scss";
import SweetAlert from "../../../components/sweet-alert/SweetAlert";
import {
  deleteBranch,
  activeInActiveBranch,
  filterTableRecords,
} from "./apiCalls";
import { notificationAction } from "../../../redux/reducers/notification_reducer";
import * as ActionTypes from "../../../redux/actions/types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import BranchFilter from "./component/Filter";
import { MdEdit } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import LoadingMask from "../../../components/Loader/LoadingMask";
import { FaBuilding } from "react-icons/fa";

class Branch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sweetalert: false,
      paginationData: [],
      branchId: null,
      download: true,
      disabled: true,
      allChecked: false,
      appLoadingState: false,
      branchList: [],
      selectedDetailInfo: {},

      selectedBranchValues: {
        person_branch: null,
        name: null,
        is_active: null,
      },
      selectedValues: {
        selected_is_active: { label: "All", value: -1 },
      },
    };
  }

  componentDidMount() {
    this.props.PageNo(1)
    this.props.Step1(false);
      this.props.RecordPerPage(10)
    this.handleFilterDataTableList();
  
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.pageno !== this.props.pageno ||
      prevProps.sortname !== this.props.sortname ||
      prevProps.sortby !== this.props.sortby
    ) {
      this.handleFilterDataTableList();
    }
    if (prevProps.recordperpage !== this.props.recordperpage) {
      this.props.PageNo(1);
      this.handleFilterDataTableList();
      this.setState({
        allChecked: false,
      });
    }
  }

  handleFiltersClear = () => {
    this.props.PageNo(1);
    this.props.RecordPerPage(10);
    this.setState(
      {
        selectedBranchValues: {
          name: "",
        },
        selectedValues: {
          selected_is_active: { label: "All", value: -1 },
        },
      },
      () => this.handleFilterDataTableList()
    );
  };

  handleFilterApply = () => {
    this.handleFilterDataTableList();
  };

  handleFilterDataTableList = () => {
    this.setState({ appLoadingState: true });
    const { selectedBranchValues, selectedValues } = this.state;
    const { selected_is_active } = selectedValues;
    const { name, is_active } = selectedBranchValues;
    filterTableRecords(
      this.props.recordperpage ? this.props.recordperpage : null,
      this.props.pageno ? this.props.pageno : null,
      this.props.sortname ? this.props.sortname : null,
      this.props.sortby ? this.props.sortby : null,
      name ? name.replace(/^\s+|\s+$/g, "") : null,
      selected_is_active.label !== "All" ? (is_active ? "1" : "0") : null
    )
      .then((response) => {
        if (response.code == 200) {
          this.setState({
            branchList: response.data.dataset,
            paginationData: response.data.pagination,
            appLoadingState: false,
          });
        }
        else if (response.code != 200){
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
          this.setState({
            appLoadingState: false,
          });
        }
      })
      .catch((error) => console.log("Erorr in getting Brnaches ", error));
  };

  handleFormFieldChange = (e) => {
    const { name, value } = e.target;
    if (name === "name") {
      this.validateField({ name, value });
      return;
    }
  };
  validateField = ({ name, value }) => {
    const { selectedBranchValues } = this.state;
    this.setState({
      selectedBranchValues: {
        ...selectedBranchValues,
        [name]: value,
      },
    });
  };
  handelDropDownChange = (selectedOption, obj) => {
    const { selectedValues, selectedBranchValues } = this.state;
    if (!selectedOption) {
      let defaultOption = { value: "", label: "All" };
      if (obj.name === "is_active") {
        this.setState({
          selectedValues: {
            ...selectedValues,
            [`selected_${obj.name}`]: defaultOption,
          },
        });
      }
      return;
    }

    const { name } = obj;

    if (obj.name === "is_active") {
      this.setState({
        selectedValues: {
          ...selectedValues,
          [`selected_${name}`]: selectedOption,
        },
        selectedBranchValues: {
          ...selectedBranchValues,
          [name]: selectedOption.value,
        },
      });
    }
  };
  handleCheckOptionToggle = (item) => {
    if (item != null) {
      this.setState({
        allChecked: false,
        branchList: this.state.branchList.map((s) =>
          s.id == item.id ? { ...item, Checked: !s.Checked } : s
        ),
      });
    } else {
      this.setState({
        allChecked: !this.state.allChecked,
        branchList: this.state.branchList.map((s) => ({
          ...s,
          Checked: !this.state.allChecked,
        })),
      });
    }
  };

  handleTableButtonClick = (item, type) => {
    if (type == "Edit") {
      this.handlBranchEdit({
        selectedBranch: item,
      });
    } else if (type == "Delete") {
      this.handleBranchDelete(item.id);
    }
  };
  handleToggleButtonClick = (item) => {
    let allBranches = this.state.branchList;
    let BranchMapping = allBranches.find(function (element) {
      return element.id == item.id;
    });
    let branchStatus = null;
    if (BranchMapping.status == 1) {
      branchStatus = 0;
    } else if (BranchMapping.status == 0) {
      branchStatus = 1;
    }
    let BulkData = {
      branch_id: [BranchMapping.id],
      status: branchStatus,
    };
    activeInActiveBranch({ BulkData })
      .then((response) => {
        if (response.code === 200) {
          const info = {
            message: response.message,
            status: "success",
          };
          this.props.notificationAction(info);
          this.handleFilterDataTableList();
          return;
        }
      })
      .catch((error, response) => {
        const info = {
          message: response.message,
          status: "error",
        };
        this.props.notificationAction(info);
      });
  };

  handlBranchEdit = (item) => {
    this.props.history.push({
      pathname: "/lookups/branch/edit-branch/" + item.selectedBranch.id,
      state: {
        selectedDetailInfo: item.selectedBranch,
      },
    });
  };

  handelSweetModalDelete = (e) => {
    let BulkData = {branch_id: [this.state.branchId] };
    
    if (e) {
      deleteBranch({ BulkData })
        .then((response) => {
          if (response.code === 200) {
            const info = {
              message: response.message,
              status: "success",
            };
            this.setState({
              sweetalert: false,
            });
            this.props.notificationAction(info);
            this.handleFilterDataTableList();
            return;
          }
          else if (response.code != 200) {
            const info = {
              message: response.message,
              status: "error",
            };
            this.props.notificationAction(info);
            this.handleFilterDataTableList();
            this.setState({
              allChecked: false,
              sweetalert: false,
            });
            return;
          }
        })
        .catch((error, response) => {
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
        });
    } else {
      this.setState({
        sweetalert: false,
      });
    }
  };
  handleBranchDelete = (Id) => {
    this.setState({
      branchId: Id,
      sweetalert: true,
    });
  };
  handleGettingBulkRecord = (item) => {
    const listArray = [];
    item.data.map((item) => {
        listArray.push(item.id);
    });
    let BulkData = {
      branch_id: listArray,
      status: item.status,
    };
    if (item.status == "delete") {
      const BulkData = {
        branch_id: listArray
      };
      deleteBranch({ BulkData })
        .then((response) => {
          if (response.code === 200) {
            const info = {
              message: response.message,
              status: "success",
            };
            this.props.notificationAction(info);
            this.handleFilterDataTableList();
            this.setState({
              allChecked: false,
            });
            return;
          }
         else if (response.code != 200) {
            const info = {
              message: response.message,
              status: "error",
            };
            this.props.notificationAction(info);
            this.handleFilterDataTableList();
            this.setState({
              allChecked: false,
            });
            return;
          }
        })
        .catch((error, response) => {
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
        });
    } else if (item.status == "0") {
      activeInActiveBranch({ BulkData })
        .then((response) => {
          if (response.code === 200) {
            const info = {
              message: response.message,
              status: "success",
            };
            this.props.notificationAction(info);
            this.handleFilterDataTableList();
            this.setState({
              allChecked: false,
            });
            return;
          }
        })
        .catch((response) => {
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
        });
    } else if (item.status == "1") {
      activeInActiveBranch({ BulkData })
        .then((response) => {
          if (response.code === 200) {
            const info = {
              message: response.message,
              status: "success",
            };
            this.props.notificationAction(info);
            this.handleFilterDataTableList();
            this.setState({
              allChecked: false,
            });
            return;
          }
        })
        .catch((response) => {
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
        });
    }
  };
  handleExportCsv = () => {
    const { download, selectedValues } = this.state;
    const { selected_is_active } = selectedValues;
    const { name, is_active } = this.state.selectedBranchValues;
    filterTableRecords(
      this.props.recordperpage ? this.props.recordperpage : null,
      this.props.pageno ? this.props.pageno : null,
      this.props.sortname ? this.props.sortname : null,
      this.props.sortby ? this.props.sortby : null,
      name ? name : null,
      selected_is_active.label !== "All" ? (is_active ? "1" : "0") : null,
      download ? 1 : null
    )
      .then((response) => {
        if (response.code == 200) {
          if (response.data.file_url != null) {
            window.open(response.data.file_url, "_blank");
          }
        }
      })
      .catch((error) => console.log("Erorr in getting branchs ", error));
  };

  render() {
    let template;

    template = (
      <div>
        <Row>
          {this.state.appLoadingState ? <LoadingMask /> : null}
          <Col md={3} lg={3}></Col>
          <Col md={9} lg={9}>
            <SweetAlert
              show={this.state.sweetalert}
              deleteRecord={this.handelSweetModalDelete}
            />

            <Link to="/lookups/branch/add-branch">
              <Button color="primary" className="pull-right button-full-width">
                <FaBuilding /> &nbsp; Add Branch
              </Button>
            </Link>
          </Col>
        </Row>
        <BranchFilter
          onFilterApply={this.handleFilterApply}
          onExportCsv={this.handleExportCsv}
          SelectedValues={this.state.selectedValues}
          selectedFormValues={this.state.selectedBranchValues}
          onFormFieldChange={this.handleFormFieldChange}
          onDDChange={this.handelDropDownChange}
          onFilterReset={this.handleFiltersClear}
        />
        <Row>
          <Col className="pr-grid-col" xs={12} lg={12}>
            {this.state.branchList && this.state.branchList.length > 0 ? (
              <Table
                primaryKeyField="sno"
                data={this.state.branchList}
                isImageEnabled={false}
                listItemToDisplay={[{ name: "Branch", valueField: "name" },
                { name: "Short Code", valueField: "code" },
                { name: "Modified", valueField: "updated_at" }
              ]}
                onCheckOptionToggle={this.handleCheckOptionToggle}
                allChecked={this.state.allChecked}
                isCheckOption={true}
                onGettingBulkRecords={this.handleGettingBulkRecord}
                paginationData={this.state.paginationData}
                toggleButtonToDisplay={true}
                buttonsToDisplay={[
                  {
                    name: "Edit",
                    buttonLabel: "Edit",
                    icon: <MdEdit size={22} />,
                    Tooltipmessage: "Edit Branch",
                  },
                  {
                    name: "Delete",
                    color: "#CE3939",
                    icon: <MdDelete size={22} />,
                    buttonLabel: "Delete",
                    Tooltipmessage: "Delete Branch",
                  },
                ]}
                onTableButtonClick={this.handleTableButtonClick}
                onToggleStatus={this.handleToggleButtonClick}
              />
            ) : (
              <div className="text-center">
                There is no data available to show
              </div>
            )}
          </Col>
        </Row>
      </div>
    );
    return this.props.GoBackValue == false ? (
      <div className="">{template} </div>
    ) : (
      ""
    );
  }
}

const actions = (dispatch) => ({
  notificationAction: (message, status) =>
    dispatch(notificationAction(message, status)),
  goBack: (goback) => dispatch({ type: ActionTypes.GO_BACK, goback }),
  Step1: (step1) => dispatch({ type: ActionTypes.STEP_1, step1 }),
  RecordPerPage: (recordperpage) =>
    dispatch({ type: ActionTypes.RECORDS_PER_PAGE, recordperpage }),
  PageNo: (pageno) => dispatch({ type: ActionTypes.PAGE_NO, pageno }),
});
const mapStateToProps = (state) => ({
  pageno: state.tab.pageno,
  recordperpage: state.tab.recordperpage,
  GoBackValue: state.tog.goback,
  sortby: state.tab.sortBy,
  sortname: state.tab.sortName,
});

export default connect(mapStateToProps, actions)(Branch);

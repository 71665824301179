import React, { Component, createRef } from "react";
import { Col, Row, Card, CardBody, Button } from "reactstrap";
import "../../../../node_modules/bootstrap-daterangepicker/daterangepicker.css";

// const keyRef = createRef(Date.now());

export default class MAttendanceFilter extends Component {

  render() {
    const {
      onFilterAttendance,
      onFilterFieldChange,
      selectedAttendanceFilters,
    } = this.props;
    const { student_name } =
      selectedAttendanceFilters;
    return (
      <>
        <Row className="mt-0 mb-4">
          <Col>
            <Card className="card-user">
              <CardBody className="mt-4">

                <Row>
                  <Col>
                    <div className="form-row">
                      <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                        <input
                          type="text"
                          name="student_name"
                          className={`input-text`}
                          value={student_name ? student_name : ""}
                          placeholder={"Search Employee"}
                          onChange={onFilterFieldChange}
                          onBlur={onFilterFieldChange}
                          maxLength={"30"}
                        />
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12 " >
                        <div >
                          <Button color="primary" onClick={onFilterAttendance}>
                            Search
                          </Button>
                        </div>
                      </div>

                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

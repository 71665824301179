import React, { Component, isValidElement } from "react";
import CaptureImage from "../../../components/profile/CaptureImage";
import { notificationAction } from "../../../redux/reducers/notification_reducer";
import { Message } from "../../../utils/messages.js";
import { connect } from "react-redux";
import StaffForm from "./Form/StaffForm";
import { addStaff } from "../apiCalls";
import {isValidEmail} from "../../../utils/helpers"
class AddStaff extends Component {
  state = {
    showModal: false,
    picture: false,
    imgData: null,
    sid: 0,
    profile_image: null,
    selectedDOBDate: "",
    selectedStaffInfo: {
      emp_number: "",
      first_name: "",
      middle_name: "",
      rfid:"",
      email: "",
      phone_number: "",
      last_name: "",
      person_type:
        "Z0FBQUFBQmlNWVZWZDM1S1ktR05qSGhsNWQtZWk4YWt4b3QwN25IYUtEOVhmRV9rR25PVndZcl91clhjLWVJdE05X3RXVVlIcGJZcHNJM3NIVE5tMzBhSG5qWDVrN3pFbmc9PQ==",
      status: "1",
      date_of_birth: null,
      email: "",
      address_1: "",
      address_2: "",
    },
    selectedEmergencyInfo: {
      full_name: "",
      relation: "",
      phone: "",
      address_1: "",
    },
    selectedValues: {},
    validateStaff: {},
  };

  modeladd = () => {
    this.setState({ showModal: !this.state.showModal });
  };
  onChangePicture = (e) => {
    if (e.target.files[0]) {
      this.setState({ picture: true });
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        this.setState({ imgData: reader.result });
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  handleFormFieldChange = (e) => {
    const { name, value } = e.target;
    if (
      name === "first_name" ||
      name === "middle_name" ||
      name === "rfid" ||
      name === "last_name" ||
      name === "emp_number" ||
      name === "email" ||
      name === "phone_number"
    ) {
      this.validateField({ name, value });
      return;
    }
    if (
      name === "full_name" ||
      name === "relation" ||
      name === "phone" ||
      name === "address_1"
    ) {
      this.validateEmergencyField({ name, value });
      return;
    }
  };
  validateEmergencyField = ({ name, value }) => {
    const { selectedEmergencyInfo, validateStaff } = this.state;
      this.setState({
        selectedEmergencyInfo: {
          ...selectedEmergencyInfo,
          [name]: value,
        },
        validateStaff: {
          ...validateStaff,
          [`invalid${name}`]: !value,
        },
      });
  };
  validateField = ({ name, value }) => {
    const { selectedStaffInfo, validateStaff } = this.state;

    this.setState({
      selectedStaffInfo: {
        ...selectedStaffInfo,
        [name]: value,
      },
      validateStaff: {
        ...validateStaff,
        [`invalid${name}`]: !value,
      },
    });
  };
  DateToFormattedString(d) {
    var yyyy = d.getFullYear().toString();
    var mm = (d.getMonth() + 1).toString();
    var dd = d.getDate().toString();
    return (
      yyyy + "-" + (mm[1] ? mm : "0" + mm[0]) + "-" + (dd[1] ? dd : "0" + dd[0])
    );
  }

  handleDOBChange = (d) => {
    let result = d && this.DateToFormattedString(d);
    this.setState({
      selectedStaffInfo: {
        ...this.state.selectedStaffInfo,
        date_of_birth: result,
      },
      selectedDOBDate: d,
    });
  };

  handleimageAdded = (imagsid) => {
    this.state.imgData = imagsid;
    this.setState({
      selectedStaffInfo: {
        ...this.state.selectedStaffInfo,
        profile_image: imagsid,
      },
    });
  };
  handelDropDownChange = (selectedOption, obj) => {
    const { selectedStaffInfo, selectedValues, validateStaff } = this.state;
    if (!selectedOption) {
      let defaultOption = { value: "", label: "All" };
      if (obj.name === "branch" || obj.name === "department") {
        this.setState({
          selectedStaffInfo: {
            ...selectedStaffInfo,
            [obj.name]: "",
          },
          selectedValues: {
            ...selectedValues,
            [`selected_${obj.name}`]: defaultOption,
          },
        });
      }
      return;
    }

    const { value } = selectedOption;
    const { name } = obj;

    if (obj.name === "branch") {
      this.setState({
        selectedStaffInfo: {
          ...selectedStaffInfo,
          [name]: selectedOption.id,
        },
        selectedValues: {
          ...selectedValues,
          [`selected_${name}`]: selectedOption,
        },
        validateStaff: {
          ...validateStaff,
          [`invalid${name}`]: !value,
        },
      });
    }
    if (obj.name === "department") {
      this.setState({
        selectedStaffInfo: {
          ...selectedStaffInfo,
          [name]: selectedOption.id,
        },
        selectedValues: {
          ...selectedValues,
          [`selected_${name}`]: selectedOption,
        },
        validateStaff: {
          ...validateStaff,
          [`invalid${name}`]: !value,
        },
      });
    }
  };

  handleSaveStaff = () => {
    let { selectedStaffInfo, validateStaff, selectedEmergencyInfo } =
      this.state;


    if (!selectedStaffInfo.first_name) {
      this.setState({
        validateStaff: {
          ...validateStaff,
          invalidfirst_name: true,
        },
      });
      const info = {
        message: Message.FirstName,
        status: "error",
      };
      this.props.notificationAction(info);
      return;
    }
    if (!selectedStaffInfo.last_name) {
      this.setState({
        validateStaff: {
          ...validateStaff,
          invalidlast_name: true,
        },
      });
      const info = {
        message: Message.LastName,
        status: "error",
      };

      this.props.notificationAction(info);
      return;
    }

    if (!selectedStaffInfo.emp_number) {
      this.setState({
        validateStaff: {
          ...validateStaff,
          invalidemp_number: true,
        },
      });
      const info = {
        message: Message.EnterEmployeeNo,
        status: "error",
      };
      this.props.notificationAction(info);
      return;
    }
    if (this.state.imgData === null) {
      this.setState({
        validateStaff: {
          invalidperson_image: true,
        },
      });
      const info = {
        message: Message.AddImage,
        status: "error",
      };
      this.props.notificationAction(info);
      return;
    }
    if(selectedStaffInfo.email!=""){
    if (!isValidEmail(selectedStaffInfo.email)) {
      this.setState({
        validateStaff: {
          ...validateStaff,
          invalidemail: true,
        },
      });
      const info = {
        message: Message.AddValidEmail,
        status: "error",
      };
      this.props.notificationAction(info);
      return;
    }
     
  }
    
    let person = selectedStaffInfo;
    let contact = selectedEmergencyInfo;
    const selectedStaffInformation = { person, contact };
    this.setState({
      Loading: true,
    });
    addStaff(selectedStaffInformation)
      .then((response) => {
        if (response.code == 200) {
          const info = {
            message: Message.AddSuccess,
            status: "success",
          };
          this.props.history.push("/staff/list-of-staff");
          this.props.notificationAction(info);
          return;
        } else if (response.code != 200) {
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
          return;
        }
      })
      .catch((error) => {
        const info = {
          message: Message.AddFailure,
          status: "error",
        };
        this.props.notificationAction(info);
      });
  };

  render() {
    return (
      <>
        {this.state.showModal ? <CaptureImage /> : ""}
        <StaffForm
          selectedStaffInfo={this.state.selectedStaffInfo}
          selectedEmergencyInfo={this.state.selectedEmergencyInfo}
          SelectedValues={this.state.selectedValues}
          validateStaff={this.state.validateStaff}
          selectedDOBDate={this.state.selectedDOBDate}
          onFormFieldChange={this.handleFormFieldChange}
          onSave={this.handleSaveStaff}
          onDOBChange={this.handleDOBChange}
          onDDChange={this.handelDropDownChange}
          onImageAdded={this.handleimageAdded}
          fromEmployeeAdd={true}
        />
      </>
    );
  }
}

const actions = (dispatch) => ({
  notificationAction: (message, status) =>
    dispatch(notificationAction(message, status)),
});
const mapStateToProps = (state) => ({
  PageNo: state.tab.pageno,
});

export default connect(mapStateToProps, actions)(AddStaff);

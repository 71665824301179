import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Switch, Route, withRouter, Redirect } from "react-router";
import Dashboard from "../../views/dashboard/dashboard";
import Header from "../Header/Header.js";
import Sidebar from "../Sidebar/Sidebar.js";
import Footer from "../Footer/Footer.js";
import Breadcrumbs from "../Breadbrumbs/Breadcrumbs.js";
import Student from "../../views/student/StudentList";
import Staff from "../../views/staff/StaffList";
import EditStudent from "../../views/student/component/Edit";
import ImportImages from "../../views/import-images/ImportImages";
import EditStaff from "../../views/staff/component/Edit";
import Notifications from "../../views/notifications/Notifications.js";
import s from "./Layout.module.scss";
import ProfileView from "../profile/ProfileView.js";
import AddStudent from "../../views/student/component/Add";
import AddStaff from "../../views/staff/component/Add";
import Class from "../../views/lookups/class/ClassList";
import Attendance from "../../views/student-reports/attendance/Attendance";
import Detection from "../../views/student-reports/detection/Detection";
import StaffAttendance from "../../views/staff-reports/attendance/Attendance";
import StaffDetection from "../../views/staff-reports/detection/Detection";
import AddClass from "../../views/lookups/class/component/Add";
import DepartmentList from "../../views/lookups/Department/DepartmentList";
import AddDepartment from "../../views/lookups/Department/component/Add";
import UsersList from "../../views/users/UsersList";
import AddUsers from "../../views/users/component/Add";
import EditClass from "../../views/lookups/class/component/Edit";
import ErrorPage from "../../views/error/ErrorPage";
import DepartmentEdit from "../../views/lookups/Department/component/Edit";
import UserEdit from "../../views/users/component/Edit";
import Branch from "../../views/lookups/branch/BranchList";
import EditBranch from "../../views/lookups/branch/component/Edit";
import AddBranch from "../../views/lookups/branch/component/Add";
import Section from "../../views/lookups/section/SectionList";
import EditSection from "../../views/lookups/section/component/Edit";
import AddSection from "../../views/lookups/section/component/Add";
import Location from "../../views/lookups/location/LocationList";
import EditLocation from "../../views/lookups/location/component/Edit";
import AddLocation from "../../views/lookups/location/component/Add";
import DevicesList from "../../views/devices/DevicesList";
import DeviceConfiguration from "../../views/devices/component/DeviceConfiguration";
import ResetPassword from "../../views/login/ResetPassword";
import ManualAttendance from "../../views/student-reports/manual-attendance/ManualAttendance";
import ManualStaffAttendance from "../../views/staff-reports/manual-attendance/ManualAttendance";


class Layout extends React.Component {
  static propTypes = {
    sidebarOpened: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
  };

  static defaultProps = {
    sidebarOpened: false,
  };

  render() {
    const { message, status } = this.props;
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    const UserInfo = localStorage.getItem("user");
    const {
      staff_perms,
      users_perms,
      devices_perms,
      lookups_perms,
      student_perms,
      staff_reports_perms,
      students_reports_perms,
    } = permissions;
    return (
      <div className={s.root}>
        <div className={s.wrap}>
          <Header />
          <Sidebar />

          {message && <Notifications status={status}>{message}</Notifications>}
          <main
            className={this.props.isCollapse ? s.collapsecontent : s.content}
          >
            <Breadcrumbs url={this.props.location.pathname} />
           
           
              
              {UserInfo != "superadmin" ? 
               <Switch>
                   <Route
                path="/"
                exact
                render={() => <Redirect to="/dashboard" />}
              />
              <Route path="/dashboard/" exact component={Dashboard} />
                  <Route
                    path={
                      student_perms
                        ? ["/students", "/students/list-of-Students"]
                        : []
                    }
                    exact
                    component={Student}
                  />
                  <Route
                    path={student_perms ? ["/students/add-student"] : []}
                    exact
                    component={AddStudent}
                  />
                  <Route
                    path="/students/list-of-students/edit-student/:id"
                    render={(props) => <EditStudent {...props} />}
                  />
                  <Route
                    path={
                      students_reports_perms
                        ? ["/students-reports/attendance-report", "/unreviewed"]
                        : []
                    }
                    exact
                    component={Attendance}
                  />
                   <Route
                    path={
                      students_reports_perms
                        ? ["/students-reports/attendance-report/manual-attendance"]
                        : []
                    }
                    exact
                    component={ManualAttendance}
                  />
                  <Route
                    path={
                      students_reports_perms
                        ? ["/students-reports/detection-report", "detections"]
                        : []
                    }
                    exact
                    component={Detection}
                  />
                  <Route
                    path={staff_perms ? ["/staff", "/staff/list-of-staff"] : []}
                    exact
                    component={Staff}
                  />
                  <Route
                    path={staff_perms ? ["/staff/add-staff-member"] : []}
                    exact
                    component={AddStaff}
                  />
                  <Route
                    path={
                      staff_perms ? ["/staff/list-of-staff/edit-staff/:id"] : []
                    }
                    render={(props) => <EditStaff {...props} />}
                  />
                  <Route
                    path={
                      staff_reports_perms
                        ? ["/staff-reports/attendance-report", "/reviewed"]
                        : []
                    }
                    exact
                    component={StaffAttendance}
                  />
                  <Route
                    path={
                      staff_reports_perms
                        ? ["/staff-reports/attendance-report/manual-attendance"]
                        : []
                    }
                    exact
                    component={ManualStaffAttendance}
                  />
                  <Route
                    path={
                      staff_reports_perms
                        ? ["/staff-reports/detection-report", "detections"]
                        : []
                    }
                    exact
                    component={StaffDetection}
                  />
                  <Route
                    path={devices_perms ? ["/devices", "/devices"] : []}
                    exact
                    component={DevicesList}
                  />
                  <Route
                    path={
                      devices_perms ? ["/devices/configure-device/:id"] : []
                    }
                    render={(props) => <DeviceConfiguration {...props} />}
                  />
                  <Route
                    path={lookups_perms ? ["/lookups", "/lookups/branch"] : []}
                    exact
                    component={Branch}
                  />
                  <Route
                    path="/lookups/branch/add-branch"
                    exact
                    component={AddBranch}
                  />
                  <Route
                    path={
                      lookups_perms ? ["/lookups/branch/edit-branch/:id"] : []
                    }
                    render={(props) => <EditBranch {...props} />}
                  />
                  <Route
                    path={
                      lookups_perms ? ["/lookups", "/lookups/location"] : []
                    }
                    exact
                    component={Location}
                  />
                  <Route
                    path={
                      lookups_perms ? ["/lookups/location/add-location"] : []
                    }
                    exact
                    component={AddLocation}
                  />
                  <Route
                    path="/lookups/location/edit-location/:id"
                    render={(props) => <EditLocation {...props} />}
                  />
                  <Route
                    path={lookups_perms ? ["/lookups", "/lookups/section"] : []}
                    exact
                    component={Section}
                  />
                  <Route
                    path={lookups_perms ? ["/lookups/section/add-section"] : []}
                    exact
                    component={AddSection}
                  />
                  <Route
                    path={
                      lookups_perms ? ["/lookups/section/edit-section/:id"] : []
                    }
                    render={(props) => <EditSection {...props} />}
                  />
                  <Route
                    path={lookups_perms ? ["/lookups", "/lookups/class"] : []}
                    exact
                    component={Class}
                  />
                  <Route
                    path={lookups_perms ? ["/lookups/class/add-class"] : []}
                    exact
                    component={AddClass}
                  />
                  <Route
                    path={
                      lookups_perms ? ["/lookups/class/edit-class/:id"] : []
                    }
                    render={(props) => <EditClass {...props} />}
                  />
                  <Route
                    path={lookups_perms ? ["/lookups/department"] : []}
                    exact
                    component={DepartmentList}
                  />
                  <Route
                    path={
                      lookups_perms
                        ? ["/lookups/department/add-department"]
                        : []
                    }
                    exact
                    component={AddDepartment}
                  />
                  <Route
                    path={
                      lookups_perms
                        ? ["/lookups/department/edit-department/:id"]
                        : []
                    }
                    render={(props) => <DepartmentEdit {...props} />}
                  />
                  <Route
                    path={users_perms ? ["/users"] : []}
                    exact
                    component={UsersList}
                  />
                  <Route
                    path={users_perms ? ["/users/add-user"] : []}
                    exact
                    component={AddUsers}
                  />
                   <Route
                    path={
                      lookups_perms ? ["/users/edit-user/:id"] : []
                    }
                    render={(props) => <UserEdit {...props} />}
                  />
                  <Route
                    path="/notifications"
                    exact
                    component={Notifications}
                  />
                  <Route
                    path={student_perms ? ["/students/import-images"] : []}
                    exact
                    component={ImportImages}
                  />
                  <Route
                    path={staff_perms ? ["/staff/import-images"] : []}
                    exact
                    component={ImportImages}
                  />
                  <Route path="/Profile" exact component={ProfileView} />
                  <Route path="/reset-password" exact component={ResetPassword} />
                  <Route path="*" exact component={ErrorPage} />
                  </Switch>
               : 
               <Switch>
                  <Route
                    path="/"
                    exact
                    render={() => <Redirect to="/dashboard" />}
                  />
                  <Route path="/dashboard/" exact component={Dashboard} />
                  <Route
                    path={["/students", "/students/list-of-Students"]}
                    exact
                    component={Student}
                  />
                  <Route
                    path="/students/add-student"
                    exact
                    component={AddStudent}
                  />
                  <Route
                    path="/students/list-of-students/edit-student/:id"
                    render={(props) => <EditStudent {...props} />}
                  />
                  <Route
                    path={[
                      "/students-reports/attendance-report",
                      "/unreviewed",
                    ]}
                    exact
                    component={Attendance}
                  />
                  {/* Manual Attendance */}
                  <Route
                    path={
                         ["/students-reports/attendance-report/manual-attendance"]
                    }
                    exact
                    component={ManualAttendance}
                  />
                  <Route
                    path={["/students-reports/detection-report", "detections"]}
                    exact
                    component={Detection}
                  />
                  <Route
                    path={["/staff", "/staff/list-of-staff"]}
                    exact
                    component={Staff}
                  />
                  <Route
                    path="/staff/add-staff-member"
                    exact
                    component={AddStaff}
                  />
                  <Route
                    path="/staff/list-of-staff/edit-staff/:id"
                    render={(props) => <EditStaff {...props} />}
                  />
                  <Route
                    path={["/staff-reports/attendance-report", "/reviewed"]}
                    exact
                    component={StaffAttendance}
                  />
                  {/* staff Manual attendance */}
                  <Route
                    path={
                         ["/staff-reports/attendance-report/manual-attendance"]
                    }
                    exact
                    component={ManualStaffAttendance}
                  />
                  <Route
                    path={["/staff-reports/detection-report", "detections"]}
                    exact
                    component={StaffDetection}
                  />
                  <Route
                    path={["/devices", "/devices"]}
                    exact
                    component={DevicesList}
                  />
                  <Route
                    path="/devices/configure-device/:id"
                    render={(props) => <DeviceConfiguration {...props} />}
                  />
                  <Route
                    path={["/lookups", "/lookups/branch"]}
                    exact
                    component={Branch}
                  />
                  <Route
                    path="/lookups/branch/add-branch"
                    exact
                    component={AddBranch}
                  />
                  <Route
                    path="/lookups/branch/edit-branch/:id"
                    render={(props) => <EditBranch {...props} />}
                  />
                  <Route
                    path={["/lookups", "/lookups/location"]}
                    exact
                    component={Location}
                  />
                  <Route
                    path="/lookups/location/add-location"
                    exact
                    component={AddLocation}
                  />
                  <Route
                    path="/lookups/location/edit-location/:id"
                    render={(props) => <EditLocation {...props} />}
                  />
                  <Route
                    path={["/lookups", "/lookups/section"]}
                    exact
                    component={Section}
                  />
                  <Route
                    path="/lookups/section/add-section"
                    exact
                    component={AddSection}
                  />
                  <Route
                    path="/lookups/section/edit-section/:id"
                    render={(props) => <EditSection {...props} />}
                  />
                  <Route
                    path={["/lookups", "/lookups/class"]}
                    exact
                    component={Class}
                  />
                  <Route
                    path="/lookups/class/add-class"
                    exact
                    component={AddClass}
                  />
                  <Route
                    path="/lookups/class/edit-class/:id"
                    render={(props) => <EditClass {...props} />}
                  />
                  <Route
                    path="/lookups/department"
                    exact
                    component={DepartmentList}
                  />
                  <Route
                    path="/lookups/department/add-department"
                    exact
                    component={AddDepartment}
                  />
                  <Route
                    path="/lookups/department/edit-department/:id"
                    render={(props) => <DepartmentEdit {...props} />}
                  />
                  <Route path="/users" exact component={UsersList} />
                  <Route path="/users/add-user" exact component={AddUsers} />
                  <Route
                    path={
                      ["/users/edit-user/:id"]
                    }
                    render={(props) => <UserEdit {...props} />}
                  />
                  <Route
                    path="/notifications"
                    exact
                    component={Notifications}
                  />
                  <Route
                    path="/students/import-images"
                    exact
                    component={ImportImages}
                  />
                  <Route
                    path="/staff/import-images"
                    exact
                    component={ImportImages}
                  />
                  <Route path="/Profile" exact component={ProfileView} />
                  <Route path="/reset-password" exact component={ResetPassword} />
                  <Route path="*" exact component={ErrorPage} />
                  </Switch>
              }
          </main>
          <Footer />
        </div>
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
    message: store.group.message,
    status: store.group.status,
    isCollapse: store.navigation.isCollapse,
  };
}

export default withRouter(connect(mapStateToProps)(Layout));

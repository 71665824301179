import React from "react";
import { staffDetails, updateStaff } from "../apiCalls";
import { notificationAction } from "../../../redux/reducers/notification_reducer";
import * as ActionTypes from "../../../redux/actions/types";
import { Message } from "../../../utils/messages.js";
import { connect } from "react-redux";
import HttpService from "../../../services/HttpService";
import StaffForm from "./Form/StaffForm";
import LoadingMask from "../../../components/Loader/LoadingMask";
import {isValidEmail} from "../../../utils/helpers"

class EditStaff extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:true,
      id: props.match.params.id,
      staffList: [],
      appLoadingState: false,
      validateStaff: {},
      selectedJoiningDate: null,
      selectedDOBDate: null,
      passData: false,
      person_type: "Z0FBQUFBQmlNMHJKUXRmdzlMVkxSOF9pR0pGeXZBaHd6R3RMblVtVEg1TDI3VlJvOTBRemk2TkdEdmpJUEFtZzRHNTYtUjNWZ3o0OTNFeVZvWE1PUWdjcDBTNHZNYmhzMHc9PQ==",
      selectedStaffInfo: { },
      selectedEmergencyInfo: {},
      selectedValues: {
        selected_department: {},
        selected_branch: {},
      },
      department: [],
      branch: [],
    };
  }
  componentDidMount() {

    let staffId = { person: this.state.id, person_type:"Z0FBQUFBQmlNWVZWZDM1S1ktR05qSGhsNWQtZWk4YWt4b3QwN25IYUtEOVhmRV9rR25PVndZcl91clhjLWVJdE05X3RXVVlIcGJZcHNJM3NIVE5tMzBhSG5qWDVrN3pFbmc9PQ==" };
    staffDetails({ person: staffId })
      .then((response) => {
        if (response.code == 200) {
          const { person, contact } = response.data;
          this.setState(
            {
              selectedCompanyInfo: person,
              selectedEmergencyInfo: contact,
              loading:false,
            },
            () => this.handleEmployeEdit(response.data)
          );
        } else if (response.code != 200) {
          this.setState({loading:false})
          this.props.notificationAction(info);
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.history.push("/");
          return;
        } else {
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
          return;
        }
      })
      .catch((error) => {
        console.log(error);
      });
    this.getLookUp();
  }
  async getLookUp() {
    this.setState({ department: await HttpService.getDepartmentList() });
    this.setState({ branch: await HttpService.getSitesList() });
  }

  handleimageAdded = (imageid) => {
    this.setState({
      selectedStaffInfo: {
        ...this.state.selectedStaffInfo,
        image_id: imageid,
      },
    });
  };

  DateToFormattedString(d) {
    var yyyy = d.getFullYear().toString();
    var mm = (d.getMonth() + 1).toString();
    var dd = d.getDate().toString();
    return (
      yyyy + "-" + (mm[1] ? mm : "0" + mm[0]) + "-" + (dd[1] ? dd : "0" + dd[0])
    );
  }

  handleJoiningDatechange = (d) => {
    let result = d && this.DateToFormattedString(d);
    this.setState({
      selectedStaffInfo: {
        ...this.state.selectedStaffInfo,
        joining_date: result,
      },
      selectedJoiningDate: d,
    });
  };
  handleDOBChange = (d) => {
    let result = d && this.DateToFormattedString(d);
    this.setState({
      selectedStaffInfo: {
        ...this.state.selectedStaffInfo,
        date_of_birth: result,
      },
      selectedDOBDate: d,
    });
  };

  findArrayElementByTitle(array, title) {
    return array.find((element) => {
      return (
        element.value.toLowerCase() === title.toLowerCase() ||
        element.label.toLowerCase() === title.toLowerCase()
      );
    });
  }

  handleUpdateStaff = () => {
    let { selectedStaffInfo, validateStaff } = this.state;
    if (!selectedStaffInfo.first_name) {
      this.setState({
        validateStaff: {
          ...validateStaff,
          invalidfirst_name: true,
        },
      });
      const info = {
        message: Message.FirstName,
        status: "error",
      };
      this.props.notificationAction(info);
      return;
    }
    if (!selectedStaffInfo.last_name) {
      this.setState({
        validateStaff: {
          ...validateStaff,
          invalidlast_name: true,
        },
      });
      const info = {
        message: Message.LastName,
        status: "error",
      };
      this.props.notificationAction(info);
      return;
    }
    if (!selectedStaffInfo.gu_id) {
      this.setState({
        validateStaff: {
          ...validateStaff,
          invalidemp_number: true,
        },
      });
      const info = {
        message: Message.EnterEmployeeNo,
        status: "error",
      };
      this.props.notificationAction(info);
      return;
    }
    if(selectedStaffInfo.email!=""){
      if (!isValidEmail(selectedStaffInfo.email)) {
        this.setState({
          validateStaff: {
            ...validateStaff,
            invalidemail: true,
          },
        });
        const info = {
          message: Message.AddValidEmail,
          status: "error",
        };
        this.props.notificationAction(info);
        return;
      }
    }
     let { selectedEmergencyInfo, person_type } = this.state;

    const eid = this.state.id;

    let postData = { person_id: eid, person: selectedStaffInfo, contact: selectedEmergencyInfo, person_type };

    updateStaff({ postData })
      .then((response) => {
        if (response.code == 200) {
          this.props.history.push("/staff/list-of-staff");
          const info = {
            message: Message.UpdateSuccess,
            status: "success",
          };
          this.props.notificationAction(info);
        } else if (response.code != 200) {
          const info = {
            message:response.message ,
            status: "error",
          };
          this.props.notificationAction(info);
        }
      })
      .catch((error) => {
        const info = {
          message: Message.UpdateFailure,
          status: "error",
        };
        this.props.notificationAction(info);
      });
  };

  handelDropDownChange = (selectedOption, obj) => {
    const { selectedStaffInfo, selectedValues, validateStaff } =
    this.state;
  if (!selectedOption) {
    let defaultOption = { value: "", label: "All" }
    if (
      obj.name === "branch" ||
      obj.name === "department"
    ) {
      this.setState({
        selectedStaffInfo: {
          ...selectedStaffInfo,
          [obj.name]: "",
        },
        selectedValues: {
          ...selectedValues,
          [`selected_${obj.name}`]: defaultOption,
        },

      });
    }
    return
  };

  const { value } = selectedOption;
  const { name } = obj;

  if (
    obj.name === "branch"
  ) {
    this.setState({
      selectedStaffInfo: {
        ...selectedStaffInfo,
        [name]: selectedOption.id,
      },
      selectedValues: {
        ...selectedValues,
        [`selected_${name}`]: selectedOption,
      },
      validateStaff: {
        ...validateStaff,
        [`invalid${name}`]: !value,
      },
    });
  }
  if (
    obj.name === "department"
  ) {
    this.setState({
      selectedStaffInfo: {
        ...selectedStaffInfo,
        [name]: selectedOption.id,
      },
      selectedValues: {
        ...selectedValues,
        [`selected_${name}`]: selectedOption,
      },
      validateStaff: {
        ...validateStaff,
        [`invalid${name}`]: !value,
      },
    });
  }
  };

  handleFormFieldChange = (e) => {
    const { selectedStaffInfo, validateStaff } = this.state;
    const { name, value } = e.target;
    if (
      name === "first_name" ||
      name === "middle_name" ||
      name === "last_name" ||
      name === "rfid" ||
      name === "email" ||
      name === "phone_number"
    ) {
      this.validateField({ name, value });
      return;
    }
    if (
      name === "full_name" ||
      name === "relation" ||
      name === "phone" ||
      name === "address_1"
    ) {
      this.validateEmergencyField({ name, value });
      return;
    }
    if (name === "emp_number") {
        this.setState({
          selectedStaffInfo: {
            ...selectedStaffInfo,
            gu_id: value,
          },
        });
      
    }
  };
  validateEmergencyField = ({ name, value }) => {
    const { selectedEmergencyInfo, validateStaff } = this.state;
      this.setState({
        selectedEmergencyInfo: {
          ...selectedEmergencyInfo,
          [name]: value,
        },
        validateStaff: {
          ...validateStaff,
          [`invalid${name}`]: !value,
        },
      });
  };
  validateField = ({ name, value }) => {
    const { selectedStaffInfo, validateStaff } = this.state;

    this.setState({
      selectedStaffInfo: {
        ...selectedStaffInfo,
        [name]: value,
      },
      validateStaff: {
        ...validateStaff,
        [`invalid${name}`]: !value,
      },
    });
  };
  handleEmployeEdit = (staffClicked) => {

    let newSelectedValues = this.state.staffList.find(
      (c) => c.person.emp_number === staffClicked.person.emp_number
    );
    const { person, contact } = staffClicked;

    newSelectedValues = {
      ...newSelectedValues,

      selected_department: {
        value: staffClicked.person.department,
        label: staffClicked.person.department_title,
      },
      selected_branch: {
        value: staffClicked.person.branch,
        label: staffClicked.person.branch_title,
      },
    };

    this.setState({
      selectedStaffInfo: person,
      selectedEmergencyInfo: contact,
      selectedValues: newSelectedValues,
      selectedDOBDate: person.date_of_birth
        ? new Date(person.date_of_birth)
        : null,
      selectedJoiningDate: person.joining_date
        ? new Date(person.joining_date)
        : null,
      eid: staffClicked.id,
      passData: true,
    });
  };

  render() {
    let render= this.state.passData ? (
      <>
        <StaffForm
          selectedStaffInfo={this.state.selectedStaffInfo}
          selectedEmergencyInfo={this.state.selectedEmergencyInfo}
          SelectedValues={this.state.selectedValues}
          validateStaff={this.state.validateStaff}
          selectedDOBDate={this.state.selectedDOBDate}
          onFormFieldChange={this.handleFormFieldChange}
          onStaffUpdate={this.handleUpdateStaff}
          onSave={this.handleSaveStaff}
          onDOBChange={this.handleDOBChange}
          onDDChange={this.handelDropDownChange}
          onImageAdded={this.handleimageAdded}
        />
      </>
    ) : (
      ""
    );
    return  this.state.loading ? <LoadingMask /> : render
  }
}

const actions = (dispatch) => ({
  notificationAction: (message, status) =>
    dispatch(notificationAction(message, status)),
});

export default connect(null, actions)(EditStaff);

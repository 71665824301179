import React from "react";
import {
  Col,
  Row,
  Button,
} from "reactstrap";
import Table from "../../../components/Table/Table";
import "../../../assets/sass/_common.scss";
import SweetAlert from "../../../components/sweet-alert/SweetAlert";
import {
  deleteDepartment,
  activeInActiveDepartment,
  filterTableRecords,
} from "./apiCalls";
import { notificationAction } from "../../../redux/reducers/notification_reducer";
import * as ActionTypes from "../../../redux/actions/types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import DepartmentFilter from "./component/Filter";
import { MdEdit } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import LoadingMask from "../../../components/Loader/LoadingMask";
import { RiOrganizationChart } from "react-icons/ri";


class DepartmentList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sweetalert: false,
      paginationData: [],
      depId: null,
      download: true,
      allChecked: false,
      appLoadingState: false,
      depList: [],
      selectedDetailInfo: {},

      selectedSDepartmentValues: {
        name: null,
        min: null,
        max: null,
        is_active: null

      },
      selectedValues: {
        selected_is_active: { label: "All", value: -1 },
      },
    };
  }

  componentDidMount() {
    this.props.RecordPerPage(10)
    this.props.Step1(false)
    this.props.PageNo(1)
    this.handleFilterDataTableList();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.pageno !== this.props.pageno ||
      prevProps.sortname !== this.props.sortname ||
      prevProps.sortby !== this.props.sortby
    ) {
      this.handleFilterDataTableList();
    }
    if (prevProps.recordperpage !== this.props.recordperpage) {
      this.props.PageNo(1);
      this.handleFilterDataTableList();
      this.setState({
        allChecked: false,
      });
    }
  }


  handleFiltersClear = () => {
    this.props.PageNo(1);
    this.props.RecordPerPage(10);
    this.setState(
      {
        selectedSDepartmentValues: {
          name: "",
          min: "",
          max: ""
        },
        selectedValues: {
          selected_is_active: { label: "All", value: -1 },
        },
      },
      () => this.handleFilterDataTableList()
    );
  };

  handleFilterApply = () => {
    this.handleFilterDataTableList();
  };

  handleFilterDataTableList = () => {


    this.setState({ appLoadingState: true })
    const { selectedSDepartmentValues, selectedValues } = this.state;
    const { selected_is_active } = selectedValues
    const {
      name,
      min,
      max,
      is_active
    } =
      selectedSDepartmentValues;
    filterTableRecords(
      this.props.recordperpage ? this.props.recordperpage : null,
      this.props.pageno ? this.props.pageno : null,
      this.props.sortname ? this.props.sortname : null,
      this.props.sortby ? this.props.sortby : null,
      name ? name.replace(/^\s+|\s+$/g, "") : null,
      min ? min : null,
      max ? max : null,
      selected_is_active.label !== "All" ? (is_active ? "1" : "0") : null,
)
      .then((response) => {
        if (response.code == 200) {
          this.setState({
            depList: response.data.dataset,
            paginationData: response.data.pagination,
            appLoadingState: false

          });
        }
        else if (response.code != 200){
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
          this.setState({
            appLoadingState: false,
          });
        }
      })
      .catch((error) => console.log("Erorr in getting departments ", error));
  };
  validateField = ({ name, value }) => {
    const { selectedSDepartmentValues } = this.state;
    this.setState({
      selectedSDepartmentValues: {
        ...selectedSDepartmentValues,
        [name]: value,
      },
    });
  };
  handleFormFieldChange = (e) => {
    const { name, value } = e.target;
    if (name === "name" || name === "min" || name === "max") {
      this.validateField({ name, value });
      return;
    }
  };
  handelDropDownChange = (selectedOption, obj) => {
    const { selectedValues, selectedSDepartmentValues } = this.state;
    const { name } = obj;
    if (!selectedOption) {
      let defaultOption = { value: "", label: "All" };
      if (
        obj.name === "is_active"
      ) {
        this.setState({
          selectedValues: {
            ...selectedValues,
            [`selected_${obj.name}`]: defaultOption,
          },
          selectedSDepartmentValues: {
            ...selectedSDepartmentValues,
            [name]: null,
          },
        });
      }
      return;
    }
    else {
      this.setState({
        selectedValues: {
          ...selectedValues,
          [`selected_${name}`]: selectedOption,
        },
        selectedSDepartmentValues: {
          ...selectedSDepartmentValues,
          [name]: selectedOption.value,
        },
      });
    }
  };
  handleGettingBulkRecord = (item) => {
    const listArray = [];
    item.data.map((item) => {
        listArray.push(item.department_id);
    });
    let BulkData = {
      department_id: listArray,
      status: item.status,
    };
    if (item.status == "delete") {
      let BulkData = {
        department_id: listArray
      };
      deleteDepartment({ BulkData })
        .then((response) => {
          if (response.code === 200) {
            const info = {
              message: response.message,
              status: "success",
            };
            this.props.notificationAction(info);
            this.handleFilterDataTableList();
            this.setState({
              allChecked: false,
            });
            return;
          }
        })
        .catch((error, response) => {
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
        });
    } else if (item.status == "0") {
      activeInActiveDepartment({ BulkData })
        .then((response) => {
          if (response.code === 200) {
            const info = {
              message: response.message,
              status: "success",
            };
            this.props.notificationAction(info);
            this.handleFilterDataTableList();
            this.setState({
              allChecked: false,
            });
            return;
          }
        })
        .catch((response) => {
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
        });
    } else if (item.status == "1") {
      activeInActiveDepartment({ BulkData })
        .then((response) => {
          if (response.code === 200) {
            const info = {
              message: response.message,
              status: "success",
            };
            this.props.notificationAction(info);
            this.handleFilterDataTableList();
            this.setState({
              allChecked: false,
            });
            return;
          }
        })
        .catch((response) => {
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
        });
    }
  };
  handleCheckOptionToggle = (item) => {
    if (item != null) {
      this.setState({
        allChecked: false,
        depList: this.state.depList.map((s) =>
          s.department_id == item.department_id ? { ...item, Checked: !s.Checked } : s,
        ),
      });
    } else {
      this.setState({
        allChecked: !this.state.allChecked,
        depList: this.state.depList.map((s) => ({
          ...s,
          Checked: !this.state.allChecked,
        })),
      });
    }
  };

  handleTableButtonClick = (item, type) => {
    if (type == "Edit") {
      this.handleDepEdit({
        department_id: item.department_id,
      });
    } else if (type == "Delete") {
      this.handleDepDelete(item.department_id);
    }
  };

  handleToggleButtonClick = (item) => {
    let allDepartments = this.state.depList;
    let departmentMapping = allDepartments.find(function (element) {
      return element.department_id == item.department_id;
    });
    let departmentStatus = null;
    if (departmentMapping.status == 1) {
      departmentStatus = 0;
    } else if (departmentMapping.status == 0) {
      departmentStatus = 1;
    }
    let BulkData = {
      department_id: [departmentMapping.department_id],
      status: departmentStatus,
    };
    activeInActiveDepartment({ BulkData })
      .then((response) => {
        if (response.code === 200) {
          const info = {
            message: response.message,
            status: "success",
          };
          this.props.notificationAction(info);
          this.handleFilterDataTableList();
          return;
        }
      })
      .catch((error, response) => {
        const info = {
          message: response.message,
          status: "error",
        };
        this.props.notificationAction(info);
      });
  };

  handleDepEdit = (item) => {
    this.props.history.push({
      pathname: "/lookups/department/edit-department/" + item.department_id,
      state: {
        selectedDetailInfo: item.selectedBranch,
      },
    });
  };
  handelSweetModalDelete = (e) => {
    let BulkData = { department_id: [this.state.depId] };
    if (e) {
      deleteDepartment({BulkData })
        .then((response) => {
          if (response.code === 200) {
            const info = {
              message: response.message,
              status: "success",
            };
            this.setState({
              sweetalert: false
            })
            this.props.notificationAction(info);
            this.handleFilterDataTableList();
            return;
          } else if (response.code != 200) {
            const info = {
              message: response.message,
              status: "error",
            };
            this.props.notificationAction(info);
            this.handleFilterDataTableList();
            this.setState({
              allChecked: false,
              sweetalert: false,
            });
            return;
          }
        })
        .catch((error, response) => {
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
        });
    }
    else {
      this.setState({
        sweetalert: false
      })
    }

  }
  handleDepDelete = (Id) => {
    this.setState({
      depId: Id,
      sweetalert: true
    })
  };
  handleExportCsv = () => {
    const { download ,selectedValues} = this.state
    const {
      name,
      min,
      max,
      is_active
    } =
      this.state.selectedSDepartmentValues;
      const { selected_is_active } = selectedValues;
    filterTableRecords(
      this.props.recordperpage ? this.props.recordperpage : null,
      this.props.pageno ? this.props.pageno : null,
      this.props.sortname ? this.props.sortname : null,
      this.props.sortby ? this.props.sortby : null,
      name ? name.replace(/^\s+|\s+$/g, "") : null,
      min ? min : null,
      max ? max : null,
      selected_is_active.label !== "All" ? (is_active ? "1" : "0") : null,
      download ? 1 : null
    )
      .then((response) => {
        if (response.code == 200) {
          if (response.data.file_url != null) {
            window.open(response.data.file_url, "_blank");
          }
        }
      })
      .catch((error) => console.log("Erorr in getting departments ", error));
  };


  render() {
    let template;

    template = (
      <div>
        <Row>
          {this.state.appLoadingState ? <LoadingMask /> : null}
          <Col md={3} lg={3}>
          </Col>
          <Col md={9} lg={9}>
            <SweetAlert show={this.state.sweetalert}
              deleteRecord={this.handelSweetModalDelete}
            />

            <Link to="/lookups/department/add-department">
              <Button color="primary" className="pull-right button-full-width">
                <RiOrganizationChart /> &nbsp; Add Department
              </Button>
            </Link>
          </Col>
        </Row>
        <DepartmentFilter
          onFilterApply={this.handleFilterApply}
          onExportCsv={this.handleExportCsv}
          SelectedValues={this.state.selectedValues}
          selectedFormValues={this.state.selectedSDepartmentValues}
          onFormFieldChange={this.handleFormFieldChange}
          onDDChange={this.handelDropDownChange}
          onFilterReset={this.handleFiltersClear}
        />

        <Row>
          <Col className="pr-grid-col" xs={12} lg={12}>
            {this.state.depList && this.state.depList.length > 0 ? (
              <Table
                primaryKeyField="sno"
                data={this.state.depList}
                isImageEnabled={false}
                listItemToDisplay={[
                  { name: "Department", valueField: "name" },
                  { name: "Short Code", valueField: "code" },
                  { name: "No of Staff Members", valueField: "staff" },
                  { name: "Modified", valueField: "updated_at" }
                ]}
             
                isCheckOption={true}
                allChecked={this.state.allChecked}
                paginationData={this.state.paginationData}
                toggleButtonToDisplay={true}
                buttonsToDisplay={[
                  {
                    name: "Edit",
                    buttonLabel: "Edit",
                    icon: <MdEdit size={22} />,
                    Tooltipmessage: "Edit Department"
                  },
                  {
                    name: "Delete",
                    color: "#CE3939",
                    icon: <MdDelete size={22} />,
                    buttonLabel: "Delete",
                    Tooltipmessage: "Delete Department"
                  },
                ]}
                onTableButtonClick={this.handleTableButtonClick}
                onToggleStatus={this.handleToggleButtonClick}
                onCheckOptionToggle={this.handleCheckOptionToggle}
                onGettingBulkRecords={this.handleGettingBulkRecord}

              />
            ) : (
              <div className="text-center">
                There is no data available to show
              </div>
            )}
          </Col>
        </Row>
      </div>
    );

    return this.props.GoBackValue == false ? (
      <div className="">{template} </div>
    ) : (
      ""
    );
  }
}

const actions = (dispatch) => ({
  notificationAction: (message, status) =>
    dispatch(notificationAction(message, status)),
  goBack: (goback) => dispatch({ type: ActionTypes.GO_BACK, goback }),
  Step1: (step1) => dispatch({ type: ActionTypes.STEP_1, step1 }),
  RecordPerPage: (recordperpage) =>
    dispatch({ type: ActionTypes.RECORDS_PER_PAGE, recordperpage }),
  PageNo: (pageno) => dispatch({ type: ActionTypes.PAGE_NO, pageno }),
});
const mapStateToProps = (state) => ({
  pageno: state.tab.pageno,
  recordperpage: state.tab.recordperpage,
  GoBackValue: state.tog.goback,
  sortby: state.tab.sortBy,
  sortname: state.tab.sortName,
});

export default connect(mapStateToProps, actions)(DepartmentList);

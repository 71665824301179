import React from "react";
import PropTypes, { bool } from "prop-types";
import { withRouter, Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  FormText,
  Input,
} from "reactstrap";
import Widget from "../../components/Widget/Widget.js";
import ReCAPTCHA from "react-google-recaptcha"

// import { Button } from "reactstrap";
import { loginUser } from "../../redux/actions/auth";
import loginImage from "../../assets/loginImage.png";
import logoImage from "../../assets/loginfront.png";
import auth from "../../services/authorization";
import styled from "styled-components";
import StechLogo from "../../components/Icons/SidebarIcons/stechlogo.png";
import jazzLogo from "../../assets/others/Jazz/Jazz@2x.png";
import LocalStorageService from "../../services/LocalStorageService.js";
import { MdClose } from "react-icons/md";
import "../../assets/sass/_common.scss";
import s from "../../components/Sidebar/Sidebar.module.scss";
import { MdRemoveRedEye, MdVisibilityOff } from "react-icons/md";
const ImgTag = styled.img`
  opacity: 0.9;
`;
class Login extends React.Component {
  state = {
    showMessage: false,
    message: "",
    showPassword: false,
    googleCaptcha:false
  };
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };
  static isAuthenticated(token) {
    if (token) return true;
  }

  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      forgotemail: "",
      isAuthLoading: false,
      authentication: false,
      forgotform: false,
      input1: "",
      input2: "",
      input3: "",
      input4: "",
    };
  }

  doLogin = (e) => {
    e.preventDefault();
    const { username, password } = this.state;
    const localStorageService = LocalStorageService;
    if(!this.state.googleCaptcha){
    }else{
    this.props
      .dispatch(
        loginUser({
          username: username.replace(/^\s+|\s+$/g, ""),
          password: password,
        })
      )
      .then((response) => {
        if (response.data.code == 200) {
          this.setState({ authentication: true });
          localStorage.setItem("authenticated", true);
          localStorage.setItem(
            "permissions",
            JSON.stringify(response.data.data.perms)
          );
          LocalStorageService.setToken(response.data.data.tokens);
          LocalStorageService.setUserInfo(response.data.data.username);
          this.props.history.push("/");
        } else if (response.data.code != 200) {
          window.grecaptcha.reset();
          this.setState({ showMessage: true, message: response.data.message });
        }
      });
    }
  };

  changeUsername = (event) => {
    this.setState({ username: event.target.value});
  };

  changePassword = (event) => {
    this.setState({ password: event.target.value });
  };
  onEnterPress = (event) => {
    if (event.keyCode === 13) {
      this.doLogin();
    }
  };
  handleChange(evt) {
    const { name, value } = evt.target;
    this.setState({ [name]: value });
  }
  HandleForgot() {
    this.setState({ forgotform: true });
  }

  HandleChangeEmail(evt) {
    const { name, value } = evt.target;
    let valTrim = value.trim()
    console.log("value & trim", value, valTrim)
    this.setState({ [name]: value.trim() });
  }
  forgotSubmit = (e) => {
    e.preventDefault()
    if(this.state.googleCaptcha){
    e.preventDefault();
     this.props.history.push("/resetpassword");
    }
  };
  onChangeCapcha=(value) =>{
    if(value){
      this.setState({googleCaptcha:true})
    }
  }
  ExpireCapcha=(value) =>{
    if(!value){
        this.setState({googleCaptcha:false})
    }

  }

  render() {
    const { showPassword } = this.state;
    let showLogo = process.env.REACT_APP_JAZZ_LOGO;
    const { from } = this.props.location.state || {
      from: { pathname: "/dashboard" },
    };
    if (
      Login.isAuthenticated(JSON.parse(localStorage.getItem("authenticated")))
    ) {
      return <Redirect to={from} />;
    }
    

    return (
      <div className="auth-page">
        <Container className="col-12">
          <Row className="d-flex align-items-center">
            {!this.state.authentication ? (
              <>
                <Col xs={12} lg={6} className="left-column">
                  <Widget className="widget-auth widget-p-lg">
                    <div className="d-flex align-items-center justify-content-between py-3">
                      <p className="auth-header mb-0 ml-1">
                        {!this.state.forgotform ? "Login" : "Forgot Password?"}
                      </p>
                    </div>
                    <div className="auth-info my-2">
                      {!this.state.forgotform
                        ? "Welcome to Attendance Management System"
                        : "Please enter your email. A link will be sent to your email where you can reset your password."}
                    </div>
                    <div>
                      {" "}
                      {this.state.showMessage ? (
                        <p className="p-2 bg-danger login-message-fonts">
                          {this.state.message}{" "}
                          <MdClose
                            className="float-right mb-1"
                            size={22}
                            onClick={() =>
                              this.setState({ showMessage: false })
                            }
                            color="white"
                          />
                        </p>
                      ) : null}
                    </div>
                    {!this.state.forgotform ? (
                      <form onSubmit={this.doLogin}>
                        <FormGroup className="my-3">
                          <FormText className="login-text">Username</FormText>
                          <Input
                            id="name"
                            className=" pl-3"
                            value={this.state.username}
                            onChange={this.changeUsername}
                            // type="email"
                            required
                            name="username"
                            autoComplete="off"
                            placeholder=""
                          />
                        </FormGroup>
                        <FormGroup className="my-3">
                          <div className="d-flex justify-content-between">
                            <FormText className="login-text">Password</FormText>
                            {/* <Link to="#" onClick={() => this.HandleForgot()} color="primary" className="forgot-text text-primary"> */}
                            <Link
                              to="#"
                              color="primary"
                              className="forgot-text text-primary"
                              // onClick={() => this.HandleForgot()}
                            >
                              Forgot password?
                            </Link>
                          </div>
                          <div className="input-icons">
                            <i
                              onClickCapture={() => {
                                this.setState({ showPassword: !showPassword });
                              }}
                              className="icon"
                            >
                              {showPassword ? (
                                <MdRemoveRedEye size={22} />
                              ) : (
                                <MdVisibilityOff size={22} />
                              )}
                            </i>
                            <Input
                              id="password"
                              className="input-transparent pl-3"
                              value={this.state.password}
                              onChange={this.changePassword}
                              type={showPassword ? "text" : "password"}
                              required
                              autoComplete="new-password"
                              name="password"
                              placeholder=""
                            />
                          </div>
                        </FormGroup>
                        <div className="bg-widget d-flex justify-content-center">
                       { process.env.REACT_APP_RECAPTCHA_KEY &&
                         <ReCAPTCHA
                         sitekey= {process.env.REACT_APP_RECAPTCHA_KEY}
                         onChange={this.onChangeCapcha}
                         onExpired={this.ExpireCapcha}
                         />
                       }
                        </div>
                        <div className="bg-widget d-flex justify-content-center">
                          <Button
                            className=" my-3 login-btn"
                            size="lg"
                            type="submit"
                            color="primary"
                          >
                            Login
                          </Button>
                        </div>
                      </form>
                    ) : (
                      //Forgot Password Work
                      <form onSubmit={this.forgotSubmit}>
                        <FormGroup className="my-3">
                          <FormText className="login-text">Username</FormText>
                          <Input
                            id="name"
                            className=" pl-3"
                            value={this.state.forgotemail}
                            onChange={(e) => this.HandleChangeEmail(e)}
                            // type="email"
                            required
                            name="forgotemail"
                            autoComplete="off"
                            placeholder=""
                          />
                        </FormGroup>
                        <div className="bg-widget d-flex justify-content-center">
                        { process.env.REACT_APP_RECAPTCHA_KEY &&
                         <ReCAPTCHA
                         sitekey= {process.env.REACT_APP_RECAPTCHA_KEY}
                         onChange={this.onChangeCapcha}
                         onExpired={this.ExpireCapcha}
                         />
                        }
                        </div>
                        <div className="bg-widget d-flex justify-content-center">
                          <Button
                            className=" my-3 login-btn"
                            size="lg"
                            type="submit"
                            color="primary"
                          >
                            Send Reset Link
                          </Button>
                        </div>
                      </form>
                    )}
                  </Widget>
                </Col>
              </>
            ) : (
              <Col xs={12} lg={6} className="left-column">
                <Widget className="widget-auth widget-p-lg">
                  <div className="d-flex align-items-center justify-content-between py-3">
                    <p className="auth-header mb-0 ml-1">
                      Enter Confirmation Code
                    </p>
                  </div>
                  <div className="authentication-text my-2">
                    Please confirm your account by entering the 4-digit code
                    sent to your email "john.doe@email.com"
                  </div>
                  <form>
                    <FormGroup className="my-3">
                      <Row>
                        <Col>
                          {" "}
                          <Input
                            id="name"
                            className="input-square pl-3"
                            value={this.state.input1}
                            onChange={(e) => this.handleChange(e)}
                            required
                            name="input1"
                            maxLength={1}
                            autoComplete="off"
                            placeholder=""
                          />
                        </Col>
                        <Col>
                          {" "}
                          <Input
                            id="name"
                            className="input-square pl-3"
                            value={this.state.input2}
                            onChange={(e) => this.handleChange(e)}
                            required
                            name="input2"
                            maxLength={1}
                            autoComplete="off"
                            placeholder=""
                          />
                        </Col>
                        <Col>
                          {" "}
                          <Input
                            id="name"
                            className="input-square pl-3"
                            value={this.state.input3}
                            onChange={(e) => this.handleChange(e)}
                            required
                            maxLength={1}
                            name="input3"
                            autoComplete="off"
                            placeholder=""
                          />
                        </Col>
                        <Col>
                          {" "}
                          <Input
                            className="input-square pl-3"
                            value={this.state.input4}
                            onChange={(e) => this.handleChange(e)}
                            required
                            maxLength={1}
                            name="input4"
                            autoComplete="off"
                            placeholder=""
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                    <p className="text-center my-3 text-muted">
                      Didn't receive the email?
                      <span class="font-weight-bold text-primary" to="/">
                        Resend(60s)
                      </span>{" "}
                    </p>
                  </form>
                </Widget>
              </Col>
            )}
            <Col xs={0} lg={6} className="right-column">
              <img
                src={showLogo === "true" ? logoImage : loginImage}
                className="img-fluid centeredImage"
                alt="loginimage"
              />
            </Col>
            <Col className="">
              <div className="float-left">
                {showLogo === "true" ? (
                  <img className="jazzLogo" src={jazzLogo} />
                ) : null}
              </div>
              <div>
                <h6 className={"logo-power mr-3"}>
                  Powered by
                  {showLogo == "false" ? (
                    <img style={{ marginLeft: 10 }} src={StechLogo} />
                  ) : null}
                </h6>
                {showLogo == "true" ? <img src={StechLogo} /> : null}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.auth.isFetching,
    isAuthenticated: state.auth.isAuthenticated,
    errorMessage: state.auth.errorMessage,
  };
}

export default withRouter(connect(mapStateToProps)(Login));

import React from "react";
import { studentDetails, updateStudent } from "../apiCalls";
import { notificationAction } from "../../../redux/reducers/notification_reducer";
import * as ActionTypes from "../../../redux/actions/types";
import { Message } from "../../../utils/messages.js";
import { connect } from "react-redux";
import HttpService from "../../../services/HttpService";
import StudentForm from "./Form/StudentForm";
import {isValidEmail} from "../../../utils/helpers"
import LoadingMask from "../../../components/Loader/LoadingMask";
class EditStudent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:true,
      id: props.match.params.id,
      studentList: [],
      appLoadingState: false,
      validateStudent: {},
      selectedJoiningDate: null,
      selectedDOBDate: null,
      passData: false,
      person_type: "Z0FBQUFBQmlNd2dXTVg4QklvWTVlOHBMdWE3QUc4Y1FfbnhxU2RVNGpVRDVabUNucVV3S0s4TTZJSi02RjhHRzVROWVoV052eUhBbm8xbU5KbmF4YUkwVXNxeGNIRmJwN0E9PQ==",
      selectedStudentInfo: {},
      selectedGuardianInfo: {},
      selectedValues: {

        selected_person_class: {},
        selected_site: {},
        selected_section_id: {}
      },
      person_class: [],
      branch: [],
      section: [],
    };
  }


  componentDidMount() {
    let studentId = { person: this.state.id, person_type:"Z0FBQUFBQmlNd2dXTVg4QklvWTVlOHBMdWE3QUc4Y1FfbnhxU2RVNGpVRDVabUNucVV3S0s4TTZJSi02RjhHRzVROWVoV052eUhBbm8xbU5KbmF4YUkwVXNxeGNIRmJwN0E9PQ==" };
    studentDetails({ person: studentId })
      .then((response) => {
        if (response.code == 200) {
          const { person, contact } = response.data;
          this.setState({
            selectedStudentInfo: person,
            selectedGuardianInfo: contact,
            loading:false,
          }
            , () => this.handleStudentEdit(response.data));
        } else if (response.code != 200) {
          this.setState({loading:false})
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
          this.props.history.push("/");
          return;
        } else {
          const info = {
            message: response.message,
            status: "error",
          };

          // this.props.history.push("/");
          this.props.notificationAction(info);
          return;
        }
      })
      .catch((error) => {
        console.log(error);
      });
    this.getLookUp();
  }
  async getLookUp() {
    this.setState({ person_class: await HttpService.getClassList() });
    this.setState({ branch: await HttpService.getSitesList() });
    this.setState({ section: await HttpService.getSectionsList() });

  }

  handleimageAdded = (imageid) => {
    this.setState({
      selectedStudentInfo: {
        ...this.state.selectedStudentInfo,
        image_id: imageid,
      },
    });
  };

  DateToFormattedString(d) {
    var yyyy = d.getFullYear().toString();
    var mm = (d.getMonth() + 1).toString();
    var dd = d.getDate().toString();
    return (
      yyyy + "-" + (mm[1] ? mm : "0" + mm[0]) + "-" + (dd[1] ? dd : "0" + dd[0])
    );
  }

  handleJoiningDatechange = (d) => {
    let result = d && this.DateToFormattedString(d);
    this.setState({
      selectedStudentInfo: {
        ...this.state.selectedStudentInfo,
        joining_date: result,
      },
      selectedJoiningDate: d,
    });
  };
  handleDOBChange = (d) => {
    let result = d && this.DateToFormattedString(d);
    this.setState({
      selectedStudentInfo: {
        ...this.state.selectedStudentInfo,
        date_of_birth: result,
      },
      selectedDOBDate: d,
    });
  };

  findArrayElementByTitle(array, title) {
    return array.find((element) => {
      return (
        element.value.toLowerCase() === title.toLowerCase() ||
        element.label.toLowerCase() === title.toLowerCase()
      );
    });
  }
  handelNext = () => {

  };

  handleUpdateStudent = () => {
    let { selectedStudentInfo, validateStudent } = this.state;

    if (!selectedStudentInfo.first_name) {
      this.setState({
        validateStudent: {
          ...validateStudent,
          invalidfirst_name: true,
        },
      });
      const info = {
        message: Message.FirstName,
        status: "error",
      };
      this.props.notificationAction(info);
      return;
    }
    if (!selectedStudentInfo.last_name) {
      this.setState({
        validateStudent: {
          ...validateStudent,
          invalidlast_name: true,
        },
      });
      const info = {
        message: Message.LastName,
        status: "error",
      };
      this.props.notificationAction(info);
      return;
    }
    if (!selectedStudentInfo.gu_id) {
      this.setState({
        validateStudent: {
          ...validateStudent,
          invalidreg_number: true,
        },
      });
      const info = {
        message: Message.EnterRegNo,
        status: "error",
      };
      this.props.notificationAction(info);
      return;

    } 
    if(selectedStudentInfo.email!=""){
      if (!isValidEmail(selectedStudentInfo.email)) {
        this.setState({
          validateStudent: {
            ...validateStudent,
            invalidemail: true,
          },
        });
        const info = {
          message: Message.AddValidEmail,
          status: "error",
        };
        this.props.notificationAction(info);
        return;
      }
    }
    let { selectedGuardianInfo, person_type } = this.state;


    // if (selectedStudentInfo.person_class) {
    //   let results = this.findArrayElementByTitle(

    //     this.state.person_class,
    //     selectedStudentInfo.person_class
    //   );
    //   selectedStudentInfo = {
    //     ...selectedStudentInfo,
    //     person_class: results.value,
    //   };
    // }

    // if (selectedStudentInfo.branch) {
    //   let results = this.findArrayElementByTitle(
    //     this.state.branch,
    //     selectedStudentInfo.branch
    //   );
    //   selectedStudentInfo = {
    //     ...selectedStudentInfo,
    //     branch: results.value,
    //   };
    // }

    // if (selectedStudentInfo.section) {
    //   let results = this.findArrayElementByTitle(
    //     this.state.section,
    //     selectedStudentInfo.section
    //   );
    //   selectedStudentInfo = {
    //     ...selectedStudentInfo,
    //     section: results.value,
    //   };
    // }
    
    const eid = this.state.id;

    let postData = { person_id: eid, person:selectedStudentInfo, contact:selectedGuardianInfo, person_type };
    updateStudent({ postData })
      .then((response) => {
        if (response.code == 200) {
          this.props.history.push("/students/list-of-Students")
          const info = {
            message: Message.UpdateSuccess,
            status: "success",
          };
          this.props.notificationAction(info);
        } else if (response.code != 200) {
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
        }
      })
      .catch((error) => {
        const info = {
          message: Message.UpdateFailure,
          status: "error",
        };
        this.props.notificationAction(info);
      });
  };

  handelDropDownChange = (selectedOption, obj) => {
    const { selectedStudentInfo, selectedValues, validateStudent } =
      this.state;
      const { value, } = selectedOption;
      const { name } = obj;
    if (!selectedOption) {
      let defaultOption = { value: "", label: "All" }
      if (
        obj.name === "section_id" ||
        obj.name === "person_class" ||
        obj.name === "site"
      ) {
        this.setState({
          selectedStudentInfo: {
            ...selectedStudentInfo,
            [obj.name]: "",
          },
          selectedValues: {
            ...selectedValues,
            [`selected_${obj.name}`]: defaultOption,
          },

        });
      }
      return
    }
else {
      this.setState({
        selectedStudentInfo: {
          ...selectedStudentInfo,
          [name]: selectedOption.id,
        },
        selectedValues: {
          ...selectedValues,
          [`selected_${name}`]: selectedOption,
        },
        validateStudent: {
          ...validateStudent,
          [`invalid${name}`]: !value,
        },
      });
    }
  };


  handleFormFieldChange = (e) => {
    const { name, value } = e.target;
    const {selectedStudentInfo  } = this.state;
     
    if (
      name === "first_name" ||
      name === "middle_name" ||
      name === "rfid" ||
      name === "last_name" ||
      name === "roll_number" ||
      name === "phone_number" ||
      name === "email"
    
    ) {
      this.validateField({ name, value });
      return;
    }
    if (
      name === "full_name" ||
      name === "relation" ||
      name === "phone" ||
      name === "address_1" 
    ) {
      this.validateGurdianField({ name, value });
      return;
    }
    if (name === "reg_number") {
      
      this.setState({
        selectedStudentInfo: {
          ...selectedStudentInfo,
          gu_id: value,
        },
      });
    
  }
  };

  validateGurdianField = ({ name, value }) => {
    const {selectedGuardianInfo,  validateStudent  } =
      this.state;
      this.setState({
        selectedGuardianInfo: {
          ...selectedGuardianInfo,
          [name]: value,
        },
        validateStudent: {
          ...validateStudent,
          [`invalid${name}`]: !value,
        },
      });
  };
  validateField = ({ name, value }) => {
    const { selectedStudentInfo, validateStudent } =
      this.state;
      this.setState({
        selectedStudentInfo: {
          ...selectedStudentInfo,
          [name]: value,
        },
        validateStudent: {
          ...validateStudent,
          [`invalid${name}`]: !value,
        },
      });
  };



  handleStudentEdit = (studentClicked) => {
    this.setState({loading:false})
    let newSelectedValues = this.state.studentList.find(
      (c) => c.person.reg_number === studentClicked.person.reg_number
    );
    const { person, contact } = studentClicked;

    newSelectedValues = {
      ...newSelectedValues,

      selected_person_class: {
        value: studentClicked.person.person_class,
        label: studentClicked.person.class_title,
      },
      selected_site: {
        value: studentClicked.person.branch,
        label: studentClicked.person.branch,
      },
      selected_section_id: {
        value: studentClicked.person.person_section,
        label: studentClicked.person.section_title,

      }


    };
    this.setState({
      selectedStudentInfo: person,
      selectedGuardianInfo: contact,
      selectedValues: newSelectedValues,
      selectedDOBDate: person.date_of_birth ? new Date(person.date_of_birth) : null,
      selectedJoiningDate: person.joining_date ? new Date(person.joining_date) : null,
      eid: studentClicked.id,
      passData: true
    });
  };

  render() {

    let render= this.state.passData ? (
      <>
          {this.state.loading ? <LoadingMask /> : null}
        <StudentForm
          selectedStudentInfo={this.state.selectedStudentInfo}
          selectedGuardianInfo={this.state.selectedGuardianInfo}
          SelectedValues={this.state.selectedValues}
          validateStudent={this.state.validateStudent}
          selectedDOBDate={this.state.selectedDOBDate}
          onFormFieldChange={this.handleFormFieldChange}
          onStudentUpdate={this.handleUpdateStudent}
          onSave={this.handleSaveStudent}
          onDOBChange={this.handleDOBChange}
          onDDChange={this.handelDropDownChange}
          onImageAdded={this.handleimageAdded}
        />
      </>
    ) : "";
    return  this.state.loading ? <LoadingMask /> : render


  }
}

const actions = (dispatch) => ({
  notificationAction: (message, status) =>
    dispatch(notificationAction(message, status)),
  goBack: (goback) => dispatch({ type: ActionTypes.GO_BACK, goback }),
  RecordPerPage: (recordperpage) =>
    dispatch({ type: ActionTypes.RECORDS_PER_PAGE, recordperpage }),
  PageNo: (pageno) => dispatch({ type: ActionTypes.PAGE_NO, pageno }),
});
const mapStateToProps = (state) => ({
  pageno: state.tab.pageno,
  recordperpage: state.tab.recordperpage,
  GoBackValue: state.tog.goback,
  sortby: state.tab.sortBy,
  sortname: state.tab.sortName,
});

export default connect(mapStateToProps, actions)(EditStudent);

import React from "react";
import { Col, Row, Button } from "reactstrap";
import { BsPersonPlusFill } from "react-icons/bs";
import Table from "../../../components/Table/Table";
import DetectionsFilter from "./DetectionFilter";
import { notificationAction } from "../../../redux/reducers/notification_reducer";
import * as ActionTypes from "../../../redux/actions/types";
import moment from "moment";
import LoadingMask from "../../../components/Loader/LoadingMask";
import ImageModel from "../../../components/image/ImageModel";
import { MdRemoveRedEye } from "react-icons/md";
import { AiFillEyeInvisible } from "react-icons/ai";
import { connect } from "react-redux";
import { getAllDetectionsList, ignoreUnIgnoreDetection } from "./apiCalls";
let dateOne = moment().startOf("day").toDate();
let startDate = moment(dateOne).format("YYYY-MM-DD HH:mm");
let dateTwo = moment()
  .startOf("day")
  .add(23, "hour")
  .add(59, "minute")
  .toDate();
let endDate = moment(dateTwo).format("YYYY-MM-DD HH:mm");
let initialRange = startDate + " / " + endDate;
class Detection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pictureModal: false,
      imageUrl: "",
      person_type: "6",
      paginationData: [],
      toggle: false,
      selectedDateRange: initialRange,
      detectionList: [],
      download: true,
      allChecked: false,
      selectedDetectionFilters: {
        person_number: "",
        student_name: "",
        class_type: "",
        section: null,
        device: null,
        location: null,
        departments: null,
        ignore:null
      },
      selectedValues: {
        selected_section: { label: "All", value: null },
        selected_class_type: { label: "All", value: null },
        selected_location: { label: "All", value: null },
        selected_device: { label: "All", value: null },
        selected_ignore: { label: "All", value: null },
      },
    };
  }

  componentDidMount = async () => {
    await this.props.RecordPerPage(10);
    await this.props.PageNo(1);
    await this.handleFilterDataTableList();
    this.props.SortName("time_stamp");

  };

  handelStudentNameChange = (selectedOption) => {
    if (!selectedOption) return;
    this.setState({
      selectedStatus: selectedOption,
    });
  };
  componentDidUpdate(prevProps) {
    if (
      prevProps.sortname !== this.props.sortname ||
      prevProps.sortby !== this.props.sortby
    ) {
      // this.props.Loading(true)
      this.handleFilterDataTableList();
    }
    if (prevProps.pageno !== this.props.pageno) {
      this.handleFilterDataTableList();
    }
    if (prevProps.recordperpage !== this.props.recordperpage) {
      // this.props.Loading(true);
      this.props.PageNo(1);
      this.handleFilterDataTableList();
    }
  }

  handleDetectionClear = (e) => {
    let startDate = moment().startOf("date").format("YYYY-MM-DD HH:mm");
    let endDate = moment()
      .startOf("date")
      .hours(23)
      .minutes(59, "hour")
      .seconds(59, "min");
    let Range = startDate + " / " + endDate.format("YYYY-MM-DD HH:mm");
    this.props.PageNo(1);
    this.props.RecordPerPage(10);
    this.setState(
      {
        selectedDetectionFilters: {
          person_number: "",
          student_name: "",
        },
        selectedDateRange: Range,
        selectedValues: {
          selected_section: { label: "All", value: null },
          selected_class_type: { label: "All", value: null },
          selected_location: { label: "All", value: null },
          selected_device: { label: "All", value: null },
          selected_ignore: { label: "All", value: null },
  
        },
      },
      () => this.handleFilterDataTableList()
    );
    this.props.SortName("time_stamp");
  };

  handleApplyFilter = () => {
    // this.props.Loading(true);
    this.props.PageNo(1);
    this.handleFilterDataTableList();
  };
  handleFilterFieldChange = (e) => {
    const { name, value } = e.target;
    const { selectedDetectionFilters } = this.state;

    if (name === "student_name" || name === "person_number") {
      this.validateFilterField({ name, value });
    }

    this.setState({
      selectedDetectionFilters: {
        ...selectedDetectionFilters,
        [name]: value,
      },
    });
    return;
  };
  validateFilterField = ({ name, value }) => {
    const { selectedDetectionFilters } = this.state;
    this.setState({
      selectedDetectionFilters: {
        ...selectedDetectionFilters,
        [name]: value,
      },
    });
  };
  handelDropDownChange = (selectedOption, obj) => {
    const { selectedValues, selectedDetectionFilters } = this.state;
    const { name } = obj;
    if (!selectedOption) {
      let defaultOption = { value: "", label: "All" };
      if (
        obj.name === "section" ||
        obj.name === "class_type" ||
        obj.name === "device" ||
        obj.name === "location" ||
        obj.name === "ignore"
      ) {
        this.setState({
          selectedValues: {
            ...selectedValues,
            [`selected_${obj.name}`]: defaultOption,
          },
          selectedDetectionFilters: {
            ...selectedDetectionFilters,
            [name]: null,
          },
        });
      }
      return;
    } else if(obj.name === "ignore"){
      this.setState({
        selectedValues: {
          ...selectedValues,
          [`selected_${name}`]: selectedOption,
        },
        selectedDetectionFilters: {
          ...selectedDetectionFilters,
          [name]: selectedOption.value,
        },
      });
    }
     else {
      this.setState({
        selectedValues: {
          ...selectedValues,
          [`selected_${name}`]: selectedOption,
        },
        selectedDetectionFilters: {
          ...selectedDetectionFilters,
          [name]: selectedOption.id,
        },
      });
    }
  };
  handleExportCsv = () => {
    const { person_type, selectedDateRange } = this.state;

    const {
      person_number,
      student_name,
      class_type,
      section,
      device,
      location,
      ignore
    } = this.state.selectedDetectionFilters;
    getAllDetectionsList(
      this.props.recordperpage ? this.props.recordperpage : null,
      this.props.pageno ? this.props.pageno : null,
      this.props.sortname ? this.props.sortname : null,
      this.props.sortby ? this.props.sortby : null,
      student_name ? student_name : null,
      person_number ? person_number : null,
      class_type ? class_type : null,
      section ? section : null,
      device ? device : null,
      location ? location : null,
      ignore ? ignore : null,
      selectedDateRange ? selectedDateRange : null,
      person_type ? person_type : null,
      this.state.download ? 1 : null
    )
      .then((response) => {
        if (response.code == 200) {
          if (response.data.file_url != null) {
            window.open(response.data.file_url, "_blank");
          }
        }
      })
      .catch((error) => console.log("Erorr in getting students ", error));
  };
  handleFilterDataTableList = async () => {
    const { person_type, selectedValues, selectedDateRange } = this.state;
    const {} = selectedValues;
    this.setState({ appLoadingState: true });

    const {
      person_number,
      student_name,
      class_type,
      section,
      device,
      location,
      ignore
    } = this.state.selectedDetectionFilters;
    await getAllDetectionsList(
      this.props.recordperpage ? this.props.recordperpage : null,
      this.props.pageno ? this.props.pageno : null,
      this.props.sortname ? this.props.sortname : null,
      this.props.sortby ? this.props.sortby : null,
      student_name ? student_name.replace(/^\s+|\s+$/g, "") : null,
      person_number ? person_number.replace(/^\s+|\s+$/g, "") : null,
      class_type ? class_type : null,
      section ? section : null,
      device ? device : null,
      location ? location : null,
      ignore ? ignore : null,
      selectedDateRange ? selectedDateRange : null,
      person_type ? person_type : null
    )
      .then((response) => {
        if (response.code == 200) {
          this.setState({
            detectionList: response.data.dataset,
            paginationData: response.data.pagination,
            appLoadingState: false,
          });
        } else if (response.code != 200) {
          this.setState({ appLoadingState: false });
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
        } else {
        }
      })
      .catch((error, response) => {
        const info = {
          message: error.message,
          status: "error",
        };

        this.props.notificationAction(info);
      });
  };
  handleDateApply = (event, picker) => {
    let startDate = picker.startDate.format("YYYY-MM-DD HH:mm");
    let endDate = picker.endDate.format("YYYY-MM-DD HH:mm");
    let Range = startDate + " / " + endDate;
    this.setState({
      selectedDateRange: Range,
    });
  };
  handelShowPictureModal = (item) => {
    this.setState({
      pictureModal: !this.state.pictureModal,
      imageUrl: item,
    });
  };
  handleCheckOptionToggle = (item) => {
    if (item != null) {
      this.setState({
        allChecked: false,
        detectionList: this.state.detectionList.map((s) =>
          s.detection_id == item.detection_id
            ? { ...item, Checked: !s.Checked }
            : s
        ),
      });
    } else {
      this.setState({
        allChecked: !this.state.allChecked,
        detectionList: this.state.detectionList.map((s) => ({
          ...s,
          Checked: !this.state.allChecked,
        })),
      });
    }
  };
  handelBulkIgnore = (item) => {
    const listArray = [];
    item.data.map((item) => {
      listArray.push(item.detection_id);
    });
    const statusData = {
      detection_id: listArray,
      ignore: item.status ? item.status : true,
    };
    ignoreUnIgnoreDetection({ payload: statusData }).then((response) => {
      if (response.code === 200) {
        const info = {
          message: response.message,
          status: "success",
        };
        this.props.notificationAction(info);
        this.handleFilterDataTableList();
        this.setState({
          allChecked: false,
        });
        return;
      } else if (response.code != 200) {
        const info = {
          message: response.message,
          status: "error",
        };
        this.props.notificationAction(info);
        this.setState({
          allChecked: false,
        });
      }
    });
  };
  handleTableButtonClick = (item, type) => {
    const { ignored } = item;

    if (type == "Ignore") {
      const statusData = {
        detection_id: [item.detection_id],
        ignore: JSON.stringify(!ignored),
      };
      ignoreUnIgnoreDetection({ payload: statusData }).then((response) => {
        if (response.code === 200) {
          const info = {
            message: response.message,
            status: "success",
          };
          this.props.notificationAction(info);
          this.handleFilterDataTableList();
          this.setState({
            allChecked: false,
          });
          return;
        } else if (response.code != 200) {
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
          this.setState({
            allChecked: false,
          });
        }
      });
    }
  };
  render() {
    let template;
    const boolRfid = process.env.REACT_APP_RFID;
    template = (
      <div>
        <Row>
          {this.state.appLoadingState ? <LoadingMask /> : null}
          <Col md={6} lg={6}></Col>
          <Col md={6} lg={6} />
        </Row>
        <DetectionsFilter
          onStudentNameChange={this.handelStudentNameChange}
          SelectedValues={this.state.selectedValues}
          onDDChange={this.handelDropDownChange}
          selectedDetectionFilters={this.state.selectedDetectionFilters}
          onFilterReset={this.handleDetectionClear}
          onExportCsv={this.handleExportCsv}
          onApply={this.handleDateApply}
          selectedDateRange={this.state.selectedDateRange}
          onFilterFieldChange={this.handleFilterFieldChange}
          onFilterDetection={this.handleApplyFilter}
        />
        <ImageModel
          show={this.state.pictureModal}
          imageUrl={this.state.imageUrl}
          onModalClose={this.handelShowPictureModal}
        />
        <Row>
          <Col className="pr-grid-col" xs={12} lg={12}>
            {this.state.detectionList && this.state.detectionList.length > 0 ? (
              <Table
                data={this.state.detectionList}
                isImageEnabled={true}
                isImageSquare={[true, true]}
                onShowPicture={this.handelShowPictureModal}
                onTableButtonClick={this.handleTableButtonClick}
                listItemToDisplay={[
                  { name: "Student", valueField: "person_name" },
                  { name: "Registration #", valueField: "reg_number" },
                  boolRfid && boolRfid === "true" ? { name: "RFID", valueField: "rfid" } : "",
                  { name: "Class", valueField: "person_class" },
                  { name: "Device", valueField: "device_name" },
                  { name: "Location", valueField: "location_name" },
                  { name: "Date Time stamp", valueField: "time_stamp" },
                ]}
                isCheckOption={[true, "detection"]}
                allChecked={this.state.allChecked}
                onCheckOptionToggle={this.handleCheckOptionToggle}
                onGettingBulkRecords={this.handelBulkIgnore}
                paginationData={this.state.paginationData}
                buttonsToDisplay={[
                  {
                    name: "Ignore",
                    value: "ShowHide",
                    icon: <MdRemoveRedEye color="green" size={22} />,
                    icon2: <AiFillEyeInvisible color="red" size={22} />,
                    Tooltipmessage: "Un-Ignore Detection",
                  },
                ]}
              />
            ) : (
              <div className="text-center">
                There is no data available to show
              </div>
            )}
          </Col>
        </Row>
      </div>
    );

    return this.props.GoBackValue == false ? (
      <div className="">{template} </div>
    ) : (
      ""
    );
  }
}

const actions = (dispatch) => ({
  notificationAction: (message, status) =>
    dispatch(notificationAction(message, status)),
  goBack: (goback) => dispatch({ type: ActionTypes.GO_BACK, goback }),
  RecordPerPage: (recordperpage) =>
    dispatch({ type: ActionTypes.RECORDS_PER_PAGE, recordperpage }),
  PageNo: (pageno) => dispatch({ type: ActionTypes.PAGE_NO, pageno }),
  SortName: (sortName) => dispatch({ type: ActionTypes.SORT_NAME, sortName }),
});
const mapStateToProps = (state) => ({
  pageno: state.tab.pageno,
  recordperpage: state.tab.recordperpage,
  GoBackValue: state.tog.goback,
  sortby: state.tab.sortBy,
  sortname: state.tab.sortName,
});

export default connect(mapStateToProps, actions)(Detection);

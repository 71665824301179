import React, { Component } from "react";
import { Col, Row, Card, CardBody, Button } from "reactstrap";
import Select from "react-select";
import HttpService from "../../../../services/HttpService";
import {MdArrowDropDown} from "react-icons/md"

export default class DepartmentFilter extends Component {
  state = {
    is_active: [],
  };
  componentDidMount() {
    this.getLookUp();
  }
  async getLookUp() {
    this.setState({ is_active: await HttpService.getStatusesList() });
  }
  render() {
    const {
      onFilterApply,
      onExportCsv,
      onDDChange,
      onFilterReset,
      selectedFormValues,
      onFormFieldChange,
      SelectedValues,
    } = this.props;
    const { name, min,max } =
    selectedFormValues;
      const {selected_is_active}=SelectedValues;
    
    return (
      <>
        <Row className="mt-2 mb-4">
          <Col>
            <Card className="card-user">
              <CardBody>
                <Row>
                  <Col>
                    <h5 className="form-heading">Filters</h5>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="form-row">
                      <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                        <label className="form-label text-muted">
                          {"Department Name"}:
                        </label>
                        <input
                          type="text"
                          name="name"
                          className={`input-text`}
                          value={name}
                          placeholder={"Search name..."}
                          onChange={onFormFieldChange}
                          maxLength={"30"}
                        />
                      </div>
                      <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12">
                        <label className="form-label text-muted">
                          {"Staff Range"}:
                        </label>
                        <input
                          type="text"
                          autoComplete="off"
                          name="min"
                          className={`input-text `}
                          value={min}
                          placeholder={"Min"}
                          onChange={onFormFieldChange}
                          maxLength={"30"}
                        />
                         
                      </div>
                      <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0 mt-3">
                        <label className="mt-4 text-muted">
                          {"-"}
                        </label>
                      </div>
                      <div className="col-lg-2 col-md-3 col-sm-12 col-xs-12">
                        <label className="form-label text-muted mt-1">
                          {""}
                        </label>
                        <input
                          type="text"
                          autoComplete="off"
                          name="max"
                          className={`input-text mt-3`}
                          value={max}
                          placeholder={"Max"}
                          onChange={onFormFieldChange}
                          maxLength={"30"}
                        />
                      </div>
                    
                      <div className=" mb-3 col-lg-3  col-md-4 col-sm-12 col-xs-12">
                        <label className="form-label text-muted">
                          {"Status"}:
                        </label>
                        <Select
                          isClearable
                          isSearchable={false}
                          name="is_active"
                          value={selected_is_active}
                          onChange={(selectedOption, name) =>
                            onDDChange(selectedOption, name)
                          }
                          options={this.state.is_active}
                          classNamePrefix="react-select"
                        />
                      </div>

                      <div className=" float-right col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="float-left full-width-column">
                          <Button
                            onClick={onExportCsv}
                            color="primary"
                            className="caret button-full-width"
                          >
                            Export &nbsp;  <MdArrowDropDown size={20}/>
                          </Button>
                        </div>
                        <div className="float-right full-width-column">
                          <Button className="button-full-width" color="primary" onClick={onFilterApply}>
                            Apply Filter
                          </Button>
                        </div>
                        <div className="float-right full-width-column">
                          <Button
                            className="mr-3 button-full-width"
                            outline
                            color="primary mr-3"
                            onClick={onFilterReset}
                          >
                            Clear Filters
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Col>
                
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

import React from "react";
import {
  Col,
  Row,
  Button,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from "reactstrap";
import {GrStackOverflow} from "react-icons/gr"
import Table from "../../components/Table/Table";
import "../../assets/sass/_common.scss";
import SweetAlert from "../../components/sweet-alert/SweetAlert";

import {
  importStaffBulk,
  deleteStaff,
  bulkActive,
  bulkInActive,
  bulkDelete,
  activeInActiveStaff,
  filterTableRecords,
} from "./apiCalls";
import { notificationAction } from "../../redux/reducers/notification_reducer";
import * as ActionTypes from "../../redux/actions/types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import StaffFilter from "./component/Filter";
import { CSVLink } from "react-csv";
import { MdEdit, MdWork } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import {AiFillFileExcel} from "react-icons/ai";
import AddCSV from "./component/AddCSV";
import LoadingMask from "../../components/Loader/LoadingMask";
import ImageModel from "../../components/image/ImageModel"
import config from "../../config";
import CsvModel from "../student/CSVModel";

let Json = {};
let bulkActionType = "";
class Staff extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pictureModal:false,
      imageUrl:"",
      person_type:"Z0FBQUFBQmlNeXNpdkVRQXBsSGl2a1h0LWEyRkFITV94ZjR3NkIyaXlUUmh2OUdiU0FkSjJBNmhlOTROU2I3UW9aOVRINUhFWjlnd1gyREk1V0ZUZ05sZnZfTEQ4dk1GLVE9PQ==",
      sweetalert: false,
      selectedCsvFile: {},
      paginationData: [],
      toggle: false,
      empId: null,
      download: true,
      csvmodel:false,
      csvupload:"",
      disabled: true,
      allChecked: false,
      dropdownOpen: false,
      appLoadingState: false,
      selectedRadioOption: "2",
      studList: [],
      selectedDetailInfo: {},

      selectedStaffValues: {
        person_name: null,
        person_number: null,
        image_status: null,
        rfid:null,
        department:null,
        branch:null,
        is_active:null

      },
      selectedValues: {
        selected_department: { label: "All", value: null },
        selected_branch: { label: "All", value: null },
        selected_is_active: { label: "All", value: -1 },
        selected_image_status: { label: "All", value: null },
      },
    };
  }

  componentDidMount=async ()=> {
   await this.props.RecordPerPage(10)
   await this.props.Step1(false)
   await this.props.PageNo(1);
   await   this.handleFilterDataTableList();
    
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.pageno !== this.props.pageno ||
      prevProps.sortname !== this.props.sortname ||
      prevProps.sortby !== this.props.sortby
    ) {
      this.handleFilterDataTableList();
    }
    if (prevProps.recordperpage !== this.props.recordperpage) {
      this.props.PageNo(1);
      this.handleFilterDataTableList();
      this.setState({
        allChecked: false,
      });
    }
  }



  handleStaffBulkDelete = () => {
    bulkActionType = "Delete";

    this.setState({ appLoadingState: false, showModal: true });
  };

  /**
   * Get the headings and data of csv on selection.
   */
  handleForce(data, fileInfo) {
    Json.action = bulkActionType;
    Json.data = data;
  }

  handelModalClose = () => {
    this.setState({
      showModal: false,
      selectedCsvFile: {},
      disabled: true
    });
  };

  handleFiltersClear = () => {
    this.props.PageNo(1);
    this.props.RecordPerPage(10);
    this.setState(
      {
        selectedStaffValues: {
          person_name: "",
          person_number: "",
          rfid:null
        },
        selectedValues: {
          selected_department: { label: "All", value: null },
          selected_branch: { label: "All", value: null },
          selected_is_active: { label: "All", value: -1 },
          selected_image_status: { label: "All", value: null },
        },
      },
      () => this.handleFilterDataTableList()
    );
  };

 

  validateFilterField = ({ name, value }) => {
    const { selectedStaffFilters } = this.state;
    this.setState({
      selectedStaffFilters: {
        ...selectedStaffFilters,
        // [`invalid${name}`]: !value,
        [name]: value,
      },
    });
  };

  handleCsvChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      this.setState({
        selectedCsvFile: e.target.files[0],
        disabled: false,

      })


    }
  };
  handleUploadCSVC = () => {
    this.setState({
      appLoadingState:true
    })
    const data = new FormData();
    data.append("csv_file", this.state.selectedCsvFile);
    data.append("flag", this.state.selectedRadioOption)
    data.append("person_type", this.state.person_type);
    importStaffBulk({ data })
      .then((response) => {
        if (response.code == 200) {
          this.setState({
            showModal: false,
            csvmodel:true,
            appLoadingState:false,
            csvupload:response.data
          });
          const info = {
            message: response.message,
            status: "success",
          };
          this.props.notificationAction(info);
          this.handleFilterDataTableList();
          return;
        } else if(response.code != 200) {
          const info = {
            message: response.message,
            status: "error",
          };
          this.setState({
            appLoadingState:false
          })
          this.props.notificationAction(info);
          return;
        }
      })
      .catch((error, response) => {
        const info = {
          message: response.message,
          status: "error",
        };
        this.props.notificationAction(info);
      });
  };
  handleFilterApply = () => {
    this.handleFilterDataTableList();
  };
   handleFilterDataTableList = () => {
    const {person_type}=this.state;


    this.setState({ appLoadingState: true })
    const { selectedStaffValues,selectedValues } = this.state;
    const {selected_is_active}=selectedValues;
    const { label} = selected_is_active;
    const {
      person_name,
      person_number,
      rfid,
      department,
      branch,
      image_status,
      is_active,
     } =
      selectedStaffValues;
    filterTableRecords(
      this.props.recordperpage ? this.props.recordperpage : null,
      this.props.pageno ? this.props.pageno : null,
      this.props.sortname ? this.props.sortname : null,
      this.props.sortby ? this.props.sortby : null,
      person_name ? person_name.replace(/^\s+|\s+$/g, "") : null,
      person_number ? person_number.replace(/^\s+|\s+$/g, "") : null,
      rfid ? rfid.replace(/^\s+|\s+$/g, "") : null,
      department ? department : null,
      branch ?branch :null,
      image_status ? image_status : null,
      label === "Active" ? true : null || label === "Inactive" ? false : null,
      person_type ?person_type :null


    )
      .then((response) => {
        if (response.code == 200) {
          this.setState({
            studList: response.data.dataset,
            paginationData: response.data.pagination,
            appLoadingState: false

          });
        }
        else if (response.code != 200){
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
          this.setState({
            appLoadingState: false,
          });
        }
      })
      .catch((error) => console.log("Erorr in getting staffs ", error));
  };


  validateField = ({ name, value }) => {
    const { selectedStaffValues } = this.state;
    this.setState({
      selectedStaffValues: {
        ...selectedStaffValues,
        [name]: value,
      },
    });
  };
  handleFormFieldChange = (e) => {
    const { name, value } = e.target;
    if (name === "person_name" || name === "person_number" || name === "rfid") {
      this.validateField({ name, value });
      return;
    }
  };
  handelDropDownChange = (selectedOption, obj) => {
    const { selectedValues, selectedStaffValues } = this.state;
    const {is_active} = selectedStaffValues
    const { name } = obj;
    if (!selectedOption) {
      let defaultOption = { value: "", label: "All" };
      if (
        obj.name === "department" ||
        obj.name === "is_active" ||
        obj.name === "image_status" ||
        obj.name === "branch"
      ) {
        this.setState({
          selectedValues: {
            ...selectedValues,
            [`selected_${obj.name}`]: defaultOption,
          },
          selectedStaffValues: {
            ...selectedStaffValues,
            [name]: null,
          },
        });
      }
      return;
    }
      this.setState({
        selectedValues: {
          ...selectedValues,
          [`selected_${name}`]: selectedOption,
        },
        selectedStaffValues: {
          ...selectedStaffValues,
          [name]: selectedOption.id,
        },
      });
      if(name == "is_active"){
        this.setState({
          selectedStaffValues: {
            ...selectedStaffValues,
           is_active: !is_active,
          },
        });
      }
      if(name == "image_status"){
        this.setState({
          selectedStaffValues: {
            ...selectedStaffValues,
           image_status: selectedOption.value,
          },
        });
      }
    
  };
  handleGettingBulkRecord = (item) => {
    const listArray = [];
    item.data.map((item) => {
        listArray.push(item.person_id);
    });
    let statusData = {
      person_id: listArray,
      status: item.status == "1" ? true :false,
    };
    if (item.status == "delete") {
      const statusData = {
        person_id: listArray,
        status: "delete",
      };
      activeInActiveStaff({ statusData })
        .then((response) => {
          if (response.code === 200) {
            const info = {
              message: response.message,
              status: "success",
            };
            this.props.notificationAction(info);
            this.handleFilterDataTableList();
            this.setState({
              allChecked: false,
            });
            return;
          }
          else if (response.code != 200){
            const info = {
              message: response.message,
              status: "error",
            };
            this.props.notificationAction(info);
            this.setState({
              allChecked: false,
            });
          }
        })
        .catch((error, response) => {
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
        });
    } else if (item.status == "0") {
      activeInActiveStaff({ statusData })
        .then((response) => {
          if (response.code === 200) {
            const info = {
              message: response.message,
              status: "success",
            };
            this.props.notificationAction(info);
            this.handleFilterDataTableList();
            this.setState({
              allChecked: false,
            });
            return;
          }
          else if (response.code != 200){
            const info = {
              message: response.message,
              status: "error",
            };
            this.props.notificationAction(info);
            this.setState({
              allChecked: false,
            });
          }
        })
        .catch((response) => {
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
        });
    } else if (item.status == "1") {
      activeInActiveStaff({ statusData })
        .then((response) => {
          if (response.code === 200) {
            const info = {
              message: response.message,
              status: "success",
            };
            this.props.notificationAction(info);
            this.handleFilterDataTableList();
            this.setState({
              allChecked: false,
            });
            return;
          }
          else if (response.code != 200){
            const info = {
              message: response.message,
              status: "error",
            };
            this.props.notificationAction(info);
            this.setState({
              allChecked: false,
            });
          }
        })
        .catch((response) => {
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
        });
    }
  };
  handleCheckOptionToggle = (item) => {
    if (item != null) {
      this.setState({
        allChecked: false,
        studList: this.state.studList.map((s) =>
          s.person_id == item.person_id ? { ...item, Checked: !s.Checked } : s
        ),
      });
    } else {
      this.setState({
        allChecked: !this.state.allChecked,
        studList: this.state.studList.map((s) => ({
          ...s,
          Checked: !this.state.allChecked,
        })),
      });
    }
  };

  handleTableButtonClick = (item, type) => {

    if (type == "Edit") {

      this.handleEmpEdit({
        selectedStaff: item,
      });
    } else if (type == "Delete") {
      this.handleEmpDelete(item.person_id);

    }
  };

  handleToggleButtonClick = (item) => {
    let allStudents = this.state.studList;
    let studentMapping = allStudents.find(function (element) {
      return element.person_id == item.person_id;
    });
    let studentStatus = null;
    if (studentMapping.status == true) {
      studentStatus = false;
    } else if (studentMapping.status == false) {
      studentStatus = true;
    }

    let statusData = {
      person_id: [studentMapping.person_id],
      is_active: studentStatus,
    };
    activeInActiveStaff({ statusData })
      .then((response) => {
        if (response.code === 200) {
          const info = {
            message: response.message,
            status: "success",
          };
          this.props.notificationAction(info);
          this.handleFilterDataTableList();
          return;
        }
      })
      .catch((error, response) => {
        const info = {
          message: response.message,
          status: "error",
        };
        this.props.notificationAction(info);
      });
  };
  handelRadioChang = (e) => {
    this.setState({
      selectedRadioOption: e.target.value,
    });
  }

  handleEmpEdit = (item) => {
    this.props.history.push({
      pathname: "/staff/list-of-staff/edit-staff/" + item.selectedStaff.person_id,
      state: {
        selectedDetailInfo: item.selectedStaff,
      },
    });
  };
  handelSweetModalDelete = (e) => {
    if (e) {
      const statusData = {
        person_id: [this.state.empId],
        status: "delete",
      };
      activeInActiveStaff({ statusData })
        .then((response) => {
          if (response.code === 200) {
            const info = {
              message: response.message,
              status: "success",
            };
            this.setState({
              sweetalert: false
            })
            this.props.notificationAction(info);
            this.handleFilterDataTableList();
            return;
          }
          else if (response.code != 200){
            const info = {
              message: response.message,
              status: "error",
            };
            this.props.notificationAction(info);
            this.setState({
              sweetalert: false,
            });
          }
        })
        .catch((error, response) => {
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
        });
    }
    else {
      this.setState({
        sweetalert: false
      })
    }

  }
  handleEmpDelete = (Id) => {
    this.setState({
      empId: Id,
      sweetalert: true
    })
  };
  CsvClose=(e)=>{
    this.setState({csvmodel:false})
  }
  handleExportCsv = () => {
    const {download,person_type,selectedValues}=this.state;
      const {
        person_name,
        person_number,
        rfid,
        department,
        branch,
        image_status,
        is_active
       } =
     this.state.selectedStaffValues;
     const {selected_is_active}=selectedValues;
     const { label} = selected_is_active;
    filterTableRecords(
      this.props.recordperpage ? this.props.recordperpage : null,
      this.props.pageno ? this.props.pageno : null,
      this.props.sortname ? this.props.sortname : null,
      this.props.sortby ? this.props.sortby : null,

      person_name ? person_name.replace(/^\s+|\s+$/g, "") : null,
      person_number ? person_number.replace(/^\s+|\s+$/g, "") : null,
      rfid ? rfid.replace(/^\s+|\s+$/g, "") : null,
      department ? department : null,
      branch ?branch :null,
      image_status ? image_status :null,
      label === "Active" ? true : null || label === "Inactive" ? false : null,
      person_type ?person_type:null,
      download ?1 :null
    )
      .then((response) => {
        if (response.code == 200) {
          if (response.data.file_url != null) {
            window.open(response.data.file_url, "_blank");
          }
        }
      })
      .catch((error) => console.log("Erorr in getting staffs ", error));
  };
  handelShowPictureModal = (item)=>{
    this.setState({
      pictureModal:!this.state.pictureModal,
      imageUrl:item
    })
   }

  render() {
    let template;
const boolRfid = process.env.REACT_APP_RFID;
    template = (
      <div>
        <Row>
        {this.state.csvmodel ?<CsvModel show={this.state.csvmodel} Csvclose={this.CsvClose} csvdata={this.state.csvupload}/>:null}
          {this.state.appLoadingState ? <LoadingMask /> : null}
          <Col md={3} lg={3}>

          </Col>
          <Col md={9} lg={9}>
            <Dropdown  size="md"
              className="pull-right"
              isOpen={this.state.dropdownOpen}
              toggle={() =>
                this.setState({ dropdownOpen: !this.state.dropdownOpen })
              }
            >
              
              <CSVLink
                filename="Staff_Sample"
                className="mr-3 ml-3"
                data={boolRfid && boolRfid  === "true"  ? config.csvDataStaff_RFID: config.csvDataStaff}
              >
                {" "}
                <Button id="caret" color="primary">
                  {" "}
                  <AiFillFileExcel size={25} />
                </Button>{" "}
              </CSVLink>
              <DropdownToggle className="" id="" color="primary">
              {<GrStackOverflow/>  }&nbsp;
                Bulk Actions
              </DropdownToggle>

              {/* <DropdownToggle split color="primary" /> */}

              <DropdownMenu  className="dropdown-border">
                <DropdownItem>
                  <Button
                  outline color="primary"
                    className="bulkButton"
                    onClick={() => this.setState({ showModal: true })}
                  >
                    Bulk Add
                  </Button>
                </DropdownItem>
                <DropdownItem>
                  <Button
                  outline color="primary"
                    className="bulkButton"
                    onClick={() => this.handleStaffBulkDelete()}
                  >
                    Bulk Delete
                  </Button>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>

            <SweetAlert show={this.state.sweetalert}
              deleteRecord={this.handelSweetModalDelete}
            />

            {/* <Button className="pull-right btn btn-primary">Bulk Actions</Button> */}
            {/* <Link to={"/staff/add"} >   */}
            <Link to="/staff/add-staff-member">
              <Button outline color="primary" className="pull-right mt-1 add-full-width">
                <MdWork /> &nbsp; Add Staff Member
              </Button>
            </Link>
          </Col>
        </Row>

        <ImageModel show={this.state.pictureModal} imageUrl={this.state.imageUrl} onModalClose={this.handelShowPictureModal}/>

        <StaffFilter
          onFilterApply={this.handleFilterApply}
          onExportCsv={this.handleExportCsv}
          SelectedValues={this.state.selectedValues}
          selectedFormValues={this.state.selectedStaffValues}
          onFormFieldChange={this.handleFormFieldChange}
          onDDChange={this.handelDropDownChange}
          onFilterReset={this.handleFiltersClear}
        />
        <AddCSV
          isOpen={this.state.showModal}
          onModalClose={this.handelModalClose}
          Disabled={this.state.disabled}
          selectedRadioOption={this.state.selectedRadioOption}
          onDone={this.handleUploadCSVC}
          onRadioChange={this.handelRadioChang}
          onCsvChange={this.handleCsvChange}
        />
        <Row>
          <Col className="pr-grid-col" xs={12} lg={12}>
            {this.state.studList && this.state.studList.length > 0 ? (
              //  {mockData.data && mockData.data.length > 0 ? (

              <Table
                primaryKeyField="sno"
                data={this.state.studList}
                isImageEnabled={true}
                isBadgeState={[true, "Image Status"]}
                onShowPicture={this.handelShowPictureModal}
                listItemToDisplay={[
                  { name: "First Name", valueField: "first_name" },
                  { name: "Last Name", valueField: "last_name" },
                  { name: "Employee #", valueField: "gu_id" },
                  boolRfid === "true" ? { name: "RFID", valueField: "rfid" } : "",
                  { name: "Department", valueField: "department" },
                  { name: "Branch", valueField: "branch" },

                ]}
                onCheckOptionToggle={this.handleCheckOptionToggle}
                onGettingBulkRecords={this.handleGettingBulkRecord}
                isCheckOption={true}
                allChecked={this.state.allChecked}
                paginationData={this.state.paginationData}
                toggleButtonToDisplay={true}
                buttonsToDisplay={[
                  {
                    name: "Edit",
                    buttonLabel: "Edit",
                    icon: <MdEdit size={22} />,
                    Tooltipmessage: "Edit Staff"
                  },
                  {
                    name: "Delete",
                    color: "#CE3939",
                    icon: <MdDelete size={22} />,
                    buttonLabel: "Delete",
                    Tooltipmessage: "Delete Staff"
                  },
                  // {
                  //   name: "Details",
                  //   color: "gray",
                  //   buttonLabel: "Delete",
                  //   Tooltipmessage:"view Details",
                  //   icon: <FaChevronRight size={22} />,
                  // },
                ]}
                onTableButtonClick={this.handleTableButtonClick}
                onToggleStatus={this.handleToggleButtonClick}
              />
            ) : (
              <div className="text-center">
                There is no data available to show
              </div>
            )}
          </Col>
        </Row>
      </div>
    );

    return this.props.GoBackValue == false ? (
      <div className="">{template} </div>
    ) : (
      ""
    );
  }
}

const actions = (dispatch) => ({
  notificationAction: (message, status) =>
    dispatch(notificationAction(message, status)),
  goBack: (goback) => dispatch({ type: ActionTypes.GO_BACK, goback }),
  Step1: (step1) => dispatch({ type: ActionTypes.STEP_1, step1 }),
  RecordPerPage: (recordperpage) =>
    dispatch({ type: ActionTypes.RECORDS_PER_PAGE, recordperpage }),
  PageNo: (pageno) => dispatch({ type: ActionTypes.PAGE_NO, pageno }),
});
const mapStateToProps = (state) => ({
  pageno: state.tab.pageno,
  recordperpage: state.tab.recordperpage,
  GoBackValue: state.tog.goback,
  sortby: state.tab.sortBy,
  sortname: state.tab.sortName,
});

export default connect(mapStateToProps, actions)(Staff);

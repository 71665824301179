import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Form
} from "reactstrap";

export default class AddCSV extends Component {
  state = {};

  render() {
    const { onModalClose, onCsvChange, onRadioChange, onDone } = this.props;
    return (
      <div>
        <Modal
          isOpen={this.props.isOpen}
          modalTransition={{ timeout: 200 }}
          backdropTransition={{ timeout: 200 }}
          toggle={onModalClose}
          size={"lg"}
          top
        >
          <ModalHeader toggle={onModalClose}>{"Import Employees"}</ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <Form>
                  <Row>
                    <Col className="pr-1">
                      {/* <label className="form-label">{"Date"}</label> */}
                      <input
                        // id="file-upload"
                        type="file"
                        accept=".csv"
                        onChange={(e) => onCsvChange(e)}
                        name="employees_csv"
                      />
                    </Col>
                  </Row>
                  {!this.props.Disabled ? (
                    <Row>
                      <Col className="pr-1">
                        <div className="m-2 p-2">
                          <label>Type :</label>
                          <input
                            type="radio"
                            className="m-2 p-2"
                            value="2"
                            checked={this.props.selectedRadioOption == "2"}
                            onChange={onRadioChange}
                          />
                          External
                          <input
                            type="radio"
                            className="m-2 p-2"
                            value="1"
                            checked={this.props.selectedRadioOption == "1"}
                            onChange={onRadioChange}
                          />
                          Internal
                       
                        </div>
                      </Col>
                    </Row>
                  ) : null}
                </Form>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button outline color="primary" onClick={onModalClose}>
              Close
            </Button>{" "}
            <Button
              disabled={this.props.Disabled}
              color="primary"
              onClick={onDone}
            >
              Upload
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

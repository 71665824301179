import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  CardBody,
} from "reactstrap";
import Select from "react-select";
import ReactTagInput from "@pathofdev/react-tag-input";
import HttpService from "../../../services/HttpService";
import Active from "../../../assets/employee/Active.svg";
import InActive from "../../../assets/employee/InActive.svg";
class EditDeviceModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: true,
      locationOptions: [],
      attendanceOptions:[],
      tags: ["Default Tag"],
    };
  }
  onTagChange = (newtag) => {
    this.setState({ tags: newtag });
  };

  handelGoBack = () => {
    const { history } = this.props;
    history.goBack();
  };
  async getLookUp() {
    this.setState({ locationOptions: await HttpService.getLocationLookupList(0) });
    this.setState({ attendanceOptions: await HttpService.getAttendanceList("attendance_type")});

  }
  componentDidMount() {
    this.getLookUp();
  }

  render() {
    const {
      onDiscard,
      onUpdate,
      EditModalValues,
      onToggleStatus,
      onDeviceNameChange,
      onLocationChange,
      selectedLocation,
      onAttendanceTypeChange,
      selectedAttendanceType,
      validateDevice
    } = this.props;
    const { status,name } = EditModalValues;
    const {invaliddevice} = validateDevice;
    return (
      <div>
        <div>
          <Modal
            className="modal-dialog modal-content rounded Model-shape modal-lg "
            isOpen={this.props.show}
            size="sm"
            toggle={this.toggle}
          >
            <ModalHeader toggle={onDiscard}>Edit Device</ModalHeader>
            <ModalBody>
              <Row>
                <Col md="12">
                  <CardBody>
                    <Form>
                      <Row>
                        <Col className="pr-1" md="6">
                          <label className="form-label required">
                            {"Device Name"}
                          </label>
                          <Input
                            type="text"
                            autoComplete="off"
                            autoFocus={invaliddevice}
                            className={`input-text-field   form-label" ${
                              invaliddevice ? "inValid" : ""
                            }`}
                            value={name}
                            onChange={onDeviceNameChange}
                            maxLength={"25"}
                          />
                        </Col>
                        <Col className="pr-1" md="6">
                          <FormGroup>
                            <label className="form-label">{"Location"}</label>
                            <Select
                              isSearchable={false}
                              isClearable={true}
                              name="company_code"
                              className={"select-input "}
                              placeholder={""}
                              value={selectedLocation}
                              onChange={onLocationChange}
                              options={this.state.locationOptions}
                              classNamePrefix="react-select"
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col className="pr-1" md="6">
                          <FormGroup>
                            <label className="form-label">{"Tags"}</label>
                            <ReactTagInput
                              tags={this.state.tags}
                              placeholder={" "}
                              readOnly={true}
                              onChange={this.onTagChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col className="pr-1" md="6">
                          <FormGroup>
                            <label className="form-label">{"Attendance Type"}</label>
                            <Select
                              isSearchable={false}
                              isClearable={true}
                              name="company_code"
                              className={"select-input "}
                              placeholder={""}
                              value={selectedAttendanceType}
                              onChange={onAttendanceTypeChange}
                              options={this.state.attendanceOptions}
                              classNamePrefix="react-select"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                      <Col className="pr-1" md="6">
                          <FormGroup>
                            <label className="form-label">{"Status"}</label>
                            <img
                              className="mt-1"
                              onClick={onToggleStatus}
                              src={status == "1" ? Active : InActive}
                            ></img>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row className="float-right">
                        <Button
                          className="mr-2"
                          outline
                          color="primary"
                          onClick={onDiscard}
                        >
                          Discard
                        </Button>

                        <Button color="primary" onClick={onUpdate}>
                          Update Device
                        </Button>
                      </Row>
                    </Form>
                  </CardBody>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </div>
      </div>
    );
  }
}

export default EditDeviceModal;

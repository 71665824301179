import React from "react";
import { Col, Row, Button } from "reactstrap";
import Table from "../../../components/Table/Table";
import "../../../assets/sass/_common.scss";
import SweetAlert from "../../../components/sweet-alert/SweetAlert";
import {
  deleteClass,
  activeInActiveClass,
  filterTableRecords,
} from "./apiCalls";
import { notificationAction } from "../../../redux/reducers/notification_reducer";
import * as ActionTypes from "../../../redux/actions/types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ClassFilter from "./component/Filter";
import { MdEdit } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import LoadingMask from "../../../components/Loader/LoadingMask";
import { SiGoogleclassroom } from "react-icons/si";
import HttpService from "../../../services/HttpService";

class Class extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sweetalert: false,
      paginationData: [],
      toggle: false,
      empId: null,
      download: true,
      disabled: true,
      allChecked: false,
      appLoadingState: false,
      studList: [],
      sectionList: [],
      selectedDetailInfo: {},

      selectedClassValues: {
        person_class: null,
        name: null,
        min: null,
        max: null,
        section_id: null,
        is_active: null,
      },
      selectedValues: {
        selected_section: { label: "All", value: null },
        selected_is_active: { label: "All", value: -1 },
      },
    };
  }

  componentDidMount() {
    this.props.RecordPerPage(10)
    this.props.Step1(false);
    this.props.PageNo(1)
    this.handleFilterDataTableList();

    this.getLookUp();
  }

  async getLookUp() {
    this.setState({ sectionList: await HttpService.getSectionsList() });
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.pageno !== this.props.pageno ||
      prevProps.sortname !== this.props.sortname ||
      prevProps.sortby !== this.props.sortby
    ) {
      this.handleFilterDataTableList();
    }
    if (prevProps.recordperpage !== this.props.recordperpage) {
      this.props.PageNo(1);
      this.handleFilterDataTableList();
      this.setState({
        allChecked: false,
      });
    }
  }

  handleFiltersClear = () => {
    this.props.PageNo(1);
    this.props.RecordPerPage(10);
    this.setState(
      {
        selectedClassValues: {
          name: "",
          min: "",
          max: "",
          section_id: "",
        },
        selectedValues: {
          selected_is_active: { label: "All", value: -1 },
          selected_section: { label: "All", value: null },
        },
      },
      () => this.handleFilterDataTableList()
    );
  };

  handleFilterApply = () => {
    this.handleFilterDataTableList();
  };

  handleFilterDataTableList = () => {
    this.setState({ appLoadingState: true });
    const { selectedClassValues, selectedValues } = this.state;
    const { selected_is_active } = selectedValues;
    const { name, min, max, is_active, section_id } = selectedClassValues;
    const { label, value } = selected_is_active;

    filterTableRecords(
      this.props.recordperpage ? this.props.recordperpage : null,
      this.props.pageno ? this.props.pageno : null,
      this.props.sortname ? this.props.sortname : null,
      this.props.sortby ? this.props.sortby : null,
      name ? name.replace(/^\s+|\s+$/g, "") : null,
      min ? min.replace(/^\s+|\s+$/g, "") : null,
      max ? max.replace(/^\s+|\s+$/g, "") : null,
      section_id ? section_id : null,
      label === "Active" ? 1 : null || label === "Inactive" ? 0 : null
    )
      .then((response) => {
        if (response.code == 200) {
          this.setState({
            studList: response.data.dataset,
            paginationData: response.data.pagination,
            appLoadingState: false,
          });
        }
        else if (response.code != 200){
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
          this.setState({
            appLoadingState: false,
          });
        }
      })
      .catch((error) => console.log("Erorr in getting students ", error));
  };
  validateField = ({ name, value }) => {
    const { selectedClassValues } = this.state;
    this.setState({
      selectedClassValues: {
        ...selectedClassValues,
        [name]: value,
      },
    });
  };
  handleFormFieldChange = (e) => {
    const { name, value } = e.target;
    if (name === "name" || name === "min" || name === "max") {
      this.validateField({ name, value });
      return;
    }
  };
  handelDropDownChange = (selectedOption, obj) => {
    const { selectedValues, selectedClassValues } = this.state;
    const { name } = obj;
    if (!selectedOption) {
      let defaultOption = { value: null, label: "All" };
      if (obj.name === "is_active" || obj.name === "section_id") {

        this.setState({
          selectedValues: {
            ...selectedValues,
            [`selected_${obj.name}`]: defaultOption,
          },
          selectedClassValues: {
            ...selectedClassValues,
            [name]: null,
          },
        });
      }
      return;
    } else {
      const { name } = obj;

      this.setState({
        selectedValues: {
          ...selectedValues,
          [`selected_${name}`]: selectedOption,
        },
        selectedClassValues: {
          ...selectedClassValues,
          [name]: selectedOption.id,
        },
      });
    }
  };

  handleGettingBulkRecord = (item) => {
    const listArray = [];
    item.data.map((item) => {
      listArray.push(item.class_id);
    });
    let statusData = {
      class_id: listArray,
      status: item.status,
    };
    if (item.status == "delete") {
      let statusData = {
        class_id: listArray
      };
      deleteClass({ statusData })
        .then((response) => {
          if (response.code === 200) {
            const info = {
              message: response.message,
              status: "success",
            };
            this.props.notificationAction(info);
            this.handleFilterDataTableList();
            this.setState({
              allChecked: false,
            });
            return;
          }
        })
        .catch((error, response) => {
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
        });
    } else if (item.status == "0") {
      activeInActiveClass({ statusData })
        .then((response) => {
          if (response.code === 200) {
            const info = {
              message: response.message,
              status: "success",
            };
            this.props.notificationAction(info);
            this.handleFilterDataTableList();
            this.setState({
              allChecked: false,
            });
            return;
          }
        })
        .catch((response) => {
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
        });
    } else if (item.status == "1") {
      activeInActiveClass({ statusData })
        .then((response) => {
          if (response.code === 200) {
            const info = {
              message: response.message,
              status: "success",
            };
            this.props.notificationAction(info);
            this.handleFilterDataTableList();
            this.setState({
              allChecked: false,
            });
            return;
          }
        })
        .catch((response) => {
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
        });
    }
  };
  handleCheckOptionToggle = (item) => {
    if (item != null) {
      this.setState({
        allChecked: false,
        studList: this.state.studList.map((s) =>
          s.class_id == item.class_id ? { ...item, Checked: !s.Checked } : s
        ),
      });
    } else {
      this.setState({
        allChecked: !this.state.allChecked,
        studList: this.state.studList.map((s) => ({
          ...s,
          Checked: !this.state.allChecked,
        })),
      });
    }
  };

  handleTableButtonClick = (item, type) => {
    if (type == "Edit") {
      this.handleClassEdit({
        selectedStudent: item,
      });
    } else if (type == "Delete") {
      this.handleSectionDelete(item.class_id);
    }
  };

  handleToggleButtonClick = (item) => {
    let allStudents = this.state.studList;
    let studentMapping = allStudents.find(function (element) {
      return element.class_id == item.class_id;
    });
    let studentStatus = null;
    if (studentMapping.status == 1) {
      studentStatus = 0;
    } else if (studentMapping.status == 0) {
      studentStatus = 1;
    }

    let statusData = {
      class_id: [studentMapping.class_id],
      status: studentStatus,
    };
    activeInActiveClass({ statusData })
      .then((response) => {
        if (response.code === 200) {
          const info = {
            message: response.message,
            status: "success",
          };
          this.props.notificationAction(info);
          this.handleFilterDataTableList();
          return;
        }
      })
      .catch((error, response) => {
        const info = {
          message: response.message,
          status: "error",
        };
        this.props.notificationAction(info);
      });
  };
  handleClassEdit = (item) => {
    this.props.history.push({
      pathname: "/lookups/class/edit-class/" + item.selectedStudent.class_id,
      state: {
        selectedDetailInfo: item.selectedStudent,
        sectionList: this.state.sectionList,
      },
    });
  };
  handelSweetModalDelete = (e) => {
    let statusData = { class_id: [this.state.empId] };
    if (e) {
      deleteClass({statusData })
        .then((response) => {
          if (response.code === 200) {
            const info = {
              message: response.message,
              status: "success",
            };
            this.setState({
              sweetalert: false,
            });
            this.props.notificationAction(info);
            this.handleFilterDataTableList();
            return;
          }else if (response.code != 200) {
            const info = {
              message: response.message,
              status: "error",
            };
            this.props.notificationAction(info);
            this.handleFilterDataTableList();
            this.setState({
              allChecked: false,
              sweetalert: false,
            });
            return;
          }
        })
        .catch((error, response) => {
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
        });
    } else {
      this.setState({
        sweetalert: false,
      });
    }
  };
  handleSectionDelete = (Id) => {
    this.setState({
      empId: Id,
      sweetalert: true,
    });
  };
  handleExportCsv = () => {
    const { download, selectedValues, selectedClassValues } = this.state;
    const { name, min, max, is_active, section_id } = selectedClassValues;
    const { selected_is_active } = selectedValues;
    filterTableRecords(
      this.props.recordperpage ? this.props.recordperpage : null,
      this.props.pageno ? this.props.pageno : null,
      this.props.sortname ? this.props.sortname : null,
      this.props.sortby ? this.props.sortby : null,
      name ? name.replace(/^\s+|\s+$/g, "") : null,
      min ? min.replace(/^\s+|\s+$/g, "") : null,
      max ? max.replace(/^\s+|\s+$/g, "") : null,
      section_id ? section_id : null,
      selected_is_active.label !== "All" ? (is_active ? "1" : "0") : null,
      download ? 1 : null
    )
      .then((response) => {
        if (response.code == 200) {
          if (response.data.file_url != null) {
            window.open(response.data.file_url, "_blank");
          }
        }
      })
      .catch((error) => console.log("Erorr in getting students ", error));
  };

  render() {
    let template;

    template = (
      <div>
        <Row>
          {this.state.appLoadingState ? <LoadingMask /> : null}
          <Col md={3} lg={3}></Col>
          <Col md={9} lg={9}>
            <SweetAlert
              show={this.state.sweetalert}
              deleteRecord={this.handelSweetModalDelete}
            />

            <Link to="/lookups/class/add-class">
              <Button color="primary" className="pull-right button-full-width">
                <SiGoogleclassroom /> &nbsp; Add Class
              </Button>
            </Link>
          </Col>
        </Row>
        <ClassFilter
          onFilterApply={this.handleFilterApply}
          onExportCsv={this.handleExportCsv}
          SelectedValues={this.state.selectedValues}
          selectedFormValues={this.state.selectedClassValues}
          onFormFieldChange={this.handleFormFieldChange}
          onDDChange={this.handelDropDownChange}
          onFilterReset={this.handleFiltersClear}
        />
        <Row>
          <Col className="pr-grid-col" xs={12} lg={12}>
            {this.state.studList && this.state.studList.length > 0 ? (
              <Table
                primaryKeyField="sno"
                data={this.state.studList}
                isImageEnabled={false}
                listItemToDisplay={[
                  { name: "Class Name", valueField: "name" },
                  { name: "Short Code", valueField: "code" },
                  { name: "Section", valueField: "sections" },
                  { name: "No. of Students", valueField: "students" },
                  { name: "Modified", valueField: "updated_at" },
                ]}
                isCheckOption={true}
                allChecked={this.state.allChecked}
                paginationData={this.state.paginationData}
                toggleButtonToDisplay={true}
                buttonsToDisplay={[
                  {
                    name: "Edit",
                    buttonLabel: "Edit",
                    icon: <MdEdit size={22} />,
                    Tooltipmessage: "Edit Class",
                  },
                  {
                    name: "Delete",
                    color: "#CE3939",
                    icon: <MdDelete size={22} />,
                    buttonLabel: "Delete",
                    Tooltipmessage: "Delete Class",
                  },
                ]}
                onTableButtonClick={this.handleTableButtonClick}
                onToggleStatus={this.handleToggleButtonClick}
                onCheckOptionToggle={this.handleCheckOptionToggle}
                onGettingBulkRecords={this.handleGettingBulkRecord}
              />
            ) : (
              <div className="text-center">
                There is no data available to show
              </div>
            )}
          </Col>
        </Row>
      </div>
    );

    return this.props.GoBackValue == false ? (
      <div className="">{template} </div>
    ) : (
      ""
    );
  }
}

const actions = (dispatch) => ({
  notificationAction: (message, status) =>
    dispatch(notificationAction(message, status)),
  goBack: (goback) => dispatch({ type: ActionTypes.GO_BACK, goback }),
  Step1: (step1) => dispatch({ type: ActionTypes.STEP_1, step1 }),
  RecordPerPage: (recordperpage) =>
    dispatch({ type: ActionTypes.RECORDS_PER_PAGE, recordperpage }),
  PageNo: (pageno) => dispatch({ type: ActionTypes.PAGE_NO, pageno }),
});
const mapStateToProps = (state) => ({
  pageno: state.tab.pageno,
  recordperpage: state.tab.recordperpage,
  GoBackValue: state.tog.goback,
  sortby: state.tab.sortBy,
  sortname: state.tab.sortName,
});

export default connect(mapStateToProps, actions)(Class);

import React, { Component, createRef } from "react";
import { Col, Row, Card, CardBody, Button } from "reactstrap";
import Select from "react-select";
import { customStyles, class_type } from "../mockdepartment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "../../../../node_modules/bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import HttpService from "../../../services/HttpService";
import {MdArrowDropDown} from "react-icons/md"

// const keyRef = createRef(Date.now());

export default class AttendanceFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      class_type: [],
      section:[],
      ranges: this.initializeRangeDate()
    };
  }
  async getLookUp() {
    this.setState({ class_type: await HttpService.getClassList() });
    this.setState({ section: await HttpService.getSectionsList() });


  }
  componentDidMount() {
    this.getLookUp();
  }
  initializeRangeDate = () => {
    const initialstate = {
      timePicker: true,
      startDate: moment().startOf('date'),
      endDate: moment().startOf('date').hours(23).minutes(59, "hour").seconds(59, "min"),
      locale: {
        format: "DD/M/YYYY hh:mm",
      },
    };
    return initialstate;
  }
  render() {
    const {
      onFilterAttendance,
      onFilterReset,
      onFilterFieldChange,
      onDDChange,
      onApply,
      SelectedValues,
      onExportCsv,
      selectedDateRange,
      selectedAttendanceFilters,
    } = this.props;
    const { student_name, person_number } =
      selectedAttendanceFilters;
      const {selected_section,selected_class_type,selected_branch}=SelectedValues;
  

    const keyRef = createRef(Date.now());


    return (
      <>
        <Row className="mt-0 mb-4">
          <Col>
            <Card className="card-user">
              <CardBody>
                <Row>
                  <Col>
                    <h5>Filters</h5>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="form-row">
                      <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                        <label className="form-label text-muted">
                          {"Student Name"}:
                        </label>
                        <input
                          type="text"
                          name="student_name"
                          className={`input-text`}
                          value={student_name ? student_name : ""}
                          placeholder={"Search name..."}
                          onChange={onFilterFieldChange}
                          onBlur={onFilterFieldChange}
                          maxLength={"30"}
                        />
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                        <label className="form-label text-muted">
                          {"Registration #"}:
                        </label>
                        <input
                          type="text"
                          autoComplete="off"
                          name="person_number"
                          className={`input-text `}
                          value={person_number ? person_number : ""}
                          placeholder={"123"}
                          onChange={onFilterFieldChange}
                          onBlur={onFilterFieldChange}
                          maxLength={"30"}
                        />
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                        <label className="form-label text-muted">
                          {"Class"}:
                        </label>
                        <Select
                          isClearable
                          isSearchable={false}
                          name="class_type"
                          className={"select-input"}
                          value={selected_class_type}
                          onChange={(selectedOption, name) =>
                            onDDChange(selectedOption, name)
                          }                          
                          options={this.state.class_type}
                          classNamePrefix="react-select"
                        />
                      </div>
                      
                      <div className="col-lg-3  col-md-4 col-sm-12 col-xs-12">
                       <label className="form-label text-muted">
                          {"Section"}:
                        </label>
                        <Select
                          isClearable
                          isSearchable={false}
                          name="section"
                          value={selected_section}
                          onChange={(selectedOption, name) =>
                            onDDChange(selectedOption, name)
                          }
                          options={this.state.section}
                          classNamePrefix="react-select"
                        />
                       </div>
                      
                      <div className="col-lg-3  col-md-4 col-sm-12 col-xs-12">
                        <label className="form-label text-muted">
                          {"Date Time Range"}:
                        </label>
                        <span className="calendar-date-range"></span>
                        <DateRangePicker
                          key={keyRef.current}
                          onApply={onApply}
                          initialSettings={this.state.ranges}
                        >
                          <input
                            type="text"
                            name="date_time_range"
                            className={`input-text`}
                            onChange={onFilterFieldChange}
                            value={selectedDateRange && selectedDateRange}
                          />
                        </DateRangePicker>
                      </div>
                      <div className=" float-right col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="float-left full-width-column">
                          <Button onClick={onExportCsv} color="primary" className="caret button-full-width">
                            Export <MdArrowDropDown size={20} className="mb-1"/>
                          </Button>
                        </div>
                        <div className="float-right full-width-column">
                          <Button className="button-full-width" color="primary" onClick={onFilterAttendance}>
                            Apply Filter
                          </Button>
                        </div>
                        <div className="float-right full-width-column">
                          <Button
                            className="mr-3 button-full-width"
                            outline
                            color="primary mr-3"
                            onClick={onFilterReset}
                          >
                            Clear Filters
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

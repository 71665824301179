import React, { Component } from 'react'
import { Row, Col, Jumbotron, Card } from 'reactstrap'
import dashboardpic from "../../assets/dashboard.png"
import "./dashboard.scss"

export default class Dashboard extends Component {
    render() {
        return (
            <>
                <Card className='attendance-dashboard'>
                    <Row md={12} lg={12} sm={12}>
                        <Col md={6} lg={6} className="mt-5">
                            <h5 className="display-5 text-primary mt-5 ml-5">What is SViz Attendance Solution?</h5>
                            <p className='ml-5 dashboard-text'>AI based attendance management system where Students and staff members are free to walk in and out of the school/college in under a second without actual contact to device.</p>
                        </Col>
                        <Col md={6} lg={6} className={"text-center"}>
                            <img
                                src={dashboardpic}
                                alt="image"
                                className="img-responsive"
                            />
                        </Col>
                    </Row>
                </Card>
                <Row md={12} lg={12} sm={12}>
                    <Col md={6} lg={6} className="mt-5">
                        <Card >
                            <h5 className="display-5 text-primary mt-5 ml-5">SOPs of Image Capturing</h5>
                            <p className='ml-5 dashboard-text'>
                                1. Face should not be covered.
                            </p>
                            <p className='ml-5 dashboard-text'>
                                2. Face should be in center.
                            </p>
                            <p className='ml-5 dashboard-text'>
                                3. Image should be blurred.
                            </p>
                            <p className='ml-5 dashboard-text'>
                                4. Image file should be JPG or JPEG.
                            </p>
                            <p className='ml-5 mb-4 dashboard-text'>
                                5. File size should be less than 10 MB.
                            </p>
                        </Card>
                    </Col>
                    <Col md={6} lg={6} className="mt-5">
                        <Card >
                            <h5 className="display-5 text-primary mt-5 ml-5">Main Features</h5>
                            <p className='ml-5 dashboard-text'>
                                • Automated Touchless Time Tracking System
                            </p>
                            <p className='ml-5 dashboard-text'>
                                • User friendly, Fast Face matching
                            </p>
                            <p className='ml-5 dashboard-text'>
                                • Quick and seamless enrollment
                            </p>
                            <p className='ml-5 dashboard-text'>
                                • Integrated with Access Control
                            </p>
                            <p className='ml-5 dashboard-text'>
                                • On screen user feedback
                            </p>
                            <p className='ml-5 dashboard-text'>
                                • Personalized on the go cloud portal
                            </p>
                            <p className='ml-5 dashboard-text'>
                                • Daily, weekly and monthly report
                            </p>
                            <p className='ml-5 dashboard-text'>
                                • Smart Integration                  
                                 </p>
                            <p className='ml-5 dashboard-text'>
                                • No additional hardware required               
                                </p>
                            <p className='ml-5 mb-4 dashboard-text'>
                                • Push Alerts & Notifications               
                            </p>

                        </Card>
                    </Col>
                </Row>
            </>
        )
    }
}
